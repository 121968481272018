import React from 'react';

interface Props {
  color: string;
}

export const IconDance: React.FunctionComponent<Props> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.75 5.99997C16.75 5.99997 14.7499 9.5 14.2499 9.99996C12.6687 11.581 9.47178 8.80985 9.47178 8.80985L10.3012 14.0732L13.7499 16.5L17.75 13.5"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 15.5L8.12716 17.462L11.25 21"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.20703 9.8092C7.20703 9.8092 4.75 8.5 4.25006 7.49994C3.75012 6.49988 3.88317 5.04959 3.75006 3.49994"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.17899 6.24258C7.39794 5.46153 7.39794 4.1952 8.17899 3.41416C8.96004 2.63311 10.2264 2.63311 11.0074 3.41416C11.7885 4.1952 11.7885 5.46153 11.0074 6.24258C10.2264 7.02363 8.96004 7.02363 8.17899 6.24258Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
