import React from 'react';
import clsx from 'clsx';
import { Stack, Text } from '@treatwell/ui';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { Context } from 'js/components/LocaleWrapper';
import styles from './VenueDetails.module.css';
import { Address } from './Address/Address';

type Props = {
  venue: VenueOutput;
  channelData: ChannelOutput;
  isActiveVenue?: boolean;
  onAddressClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export function VenueDetails(props: Props): React.ReactElement {
  const { venue, channelData, isActiveVenue = true, onAddressClick } = props;

  return (
    <Context.Consumer>
      {(value) => (
        <Stack space="sm">
          <Text
            as="h1"
            type="lgHeader"
            className={clsx(styles.name, {
              [styles.widget]: value.isWidget,
            })}
          >
            {venue.name}
          </Text>
          <div
            className={clsx(styles.details, {
              [styles.widget]: value.isWidget,
            })}
          >
            <Address
              venue={venue}
              channelData={channelData}
              isActiveVenue={isActiveVenue}
              onClick={onAddressClick}
            />
          </div>
        </Stack>
      )}
    </Context.Consumer>
  );
}
