import React from 'react';
import { PriceRange } from 'js/helpers/price/PriceRange';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { ServicePriceRangeCms } from 'js/helpers/price/ServicePriceRangeCms';
import { PricingDisplayType } from 'js/model/rainbow/venue/VenueMenuItemOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import * as priceUtilities from 'js/helpers/price/priceUtilities';
import { PriceView } from './PriceView';

interface Props {
  priceRangeOutput: PriceRangeOutput;
  channelOutput: ChannelOutput;
  servicePriceRangeCms: ServicePriceRangeCms;
  pricingDisplayType?: PricingDisplayType;
  serviceType?: string;
  inline?: boolean;
  hasParentItem?: boolean;
  alignStart?: boolean;
}

export class PriceDisplay extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      priceRangeOutput,
      channelOutput,
      servicePriceRangeCms,
      pricingDisplayType = PricingDisplayType.total,
      inline = false,
      hasParentItem = false,
      alignStart = false,
    } = this.props;

    const priceRange: PriceRange = new PriceRange(priceRangeOutput);

    const pricePrefix = priceUtilities.getPricePrefix(
      priceRange,
      servicePriceRangeCms
    );
    const priceSuffix = priceUtilities.getPriceSuffix(
      servicePriceRangeCms,
      pricingDisplayType
    );
    const discountText: string =
      priceRange.getDiscountText(servicePriceRangeCms);
    const price: string = priceUtilities.formatPrice(
      priceRange.getSalePrice(),
      channelOutput
    );
    const strikeThroughPrice = priceRange.getStrikeThroughPrice(channelOutput);

    return (
      <PriceView
        pricePrefix={pricePrefix}
        price={price}
        priceSuffix={priceSuffix}
        strikeThroughPrice={strikeThroughPrice}
        discountText={discountText}
        inline={inline}
        hasParentItem={hasParentItem}
        alignStart={alignStart}
      />
    );
  }
}
