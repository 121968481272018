import React from 'react';
import clsx from 'clsx';
import { updateUriWithOpenPopup } from 'js/helpers/uri-util';
import { Stack } from '@treatwell/ui';
import { DurationRange } from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { MenuGroup, VenueStatusType } from 'js/model/rainbow/venue/VenueOutput';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { EmployeeLevelItem } from 'js/model/rainbow/venue/EmployeeOutput';
import { Context } from 'js/components/LocaleWrapper';
import { useIsMounted } from 'js/hooks/useIsMounted';
import { LocalMenuItem } from './LocalMenuItem';
import styles from './MenuItem.module.css';

export const SERVICE_POPUP_TYPE = 'SERVICE_POPUP_TYPE';

export type VenueMenu = {
  activeTreatmentTypeId: number;
  expanded: { [key: string]: boolean };
  highlightedExpanded: { [key: string]: boolean };
  filteredMenuGroups: [{ groups: MenuGroup[]; selected: number }];
  selected: {
    [key: string]: { [key: string]: { [key: string]: boolean } };
  };
  selectedSkuIds: string[];
  selectedTreatmentTypes: number[];
};

interface Props {
  id: string;
  name?: string;
  details?: boolean;
  duration?: DurationRange;
  price?: PriceRangeOutput;
  options2D?: EmployeeLevelItem[];
  initiallyExpanded?: boolean;
  groupId?: number;
  serviceId?: string;
  venueMenu: VenueMenu;
  isItemGroup?: boolean;
  isGroupSelected?: boolean;
  channel: ChannelOutput;
  children?: React.ReactNode;
  treatmentTypeId?: number;
  onSelectItem?: (
    groupId: number | undefined,
    serviceId: string | undefined,
    optionId: string | number | undefined,
    selected: boolean,
    multi: boolean,
    treatmentTypeId: number | undefined
  ) => void;
  onToggleItem?: (id: string, expanded: boolean) => void;
  onOpenPopup?: (popupType: string, serviceId: string) => void;
  isItemSelectedInGroup?: boolean;
  isSuggestedGroup: boolean;
  multi?: boolean;
}

export function MenuItem(props: Props): React.ReactElement | null {
  const [popoverShown, setPopoverShown] = React.useState<boolean>(false);
  const isMounted = useIsMounted();

  const isItemSelected = (props: Props) => {
    const { venueMenu, groupId, serviceId, id, options2D = [] } = props;
    if (!groupId || !serviceId || !id) {
      return;
    }
    const selectedService =
      venueMenu.selected &&
      venueMenu.selected[groupId] &&
      venueMenu.selected[groupId][serviceId];

    if (selectedService) {
      const selectedServiceOptions = Object.keys(selectedService).filter(
        (key) => selectedService[key]
      );

      if (options2D.length && selectedServiceOptions.length) {
        return !!options2D.find(
          (option) => selectedServiceOptions.indexOf(option.id) !== -1
        );
      }

      return !!selectedService[id];
    }

    return false;
  };

  const togglePopoverDisplay = () => {
    setPopoverShown((popoverShown) => !popoverShown);
  };

  const selectItem = (selected: boolean, optionId?: string) => {
    const { groupId, serviceId, id, treatmentTypeId, multi = false } = props;
    if (props.onSelectItem) {
      props.onSelectItem(
        groupId,
        serviceId,
        optionId || id,
        !selected,
        multi,
        treatmentTypeId
      );
    }
  };

  const handleItemClick = (
    venueStatus: VenueStatusType,
    selected: boolean,
    expanded?: boolean
  ) => {
    if (props.initiallyExpanded) {
      return;
    }
    const { venueMenu, groupId, serviceId, id } = props;
    if (props.isItemGroup) {
      props.onToggleItem!(props.id!, !expanded);
    } else {
      if (!selected && props.options2D?.length) {
        togglePopoverDisplay();
        return;
      }

      if (serviceId === id && groupId && serviceId) {
        const optionIds = Object.keys(venueMenu.selected[groupId][serviceId]);
        // unselect all options
        optionIds.forEach((optionId) => {
          selectItem(selected, optionId);
        });
      } else if (venueStatus !== 'churned') {
        selectItem(selected);
      }
    }
  };

  const handlePopoverSubmit = (optionId: string): void => {
    selectItem(false, optionId);

    togglePopoverDisplay();
  };

  const openServiceDetails = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
    const { serviceId } = props;

    updateUriWithOpenPopup({
      serviceDetails: serviceId,
    });
    props.onOpenPopup!(SERVICE_POPUP_TYPE, serviceId!);
  };

  const {
    isItemGroup,
    isGroupSelected,
    id,
    name,
    details,
    duration,
    price,
    options2D = [],
    serviceId,
    initiallyExpanded,
    venueMenu,
    channel,
    isSuggestedGroup,
    isItemSelectedInGroup,
  } = props;
  if (!id) {
    return null;
  }
  const isSelected = isMounted && isItemSelected(props) && isGroupSelected;
  const isHighlightedExpanded =
    (venueMenu.highlightedExpanded && venueMenu.highlightedExpanded[id]) ||
    initiallyExpanded;
  const isExpanded =
    isMounted &&
    ((venueMenu.expanded && venueMenu.expanded[id]) || initiallyExpanded);

  const itemGroupItems = isItemGroup ? props.children : null;
  const menuItemStyle = clsx({
    [styles.enabled]: !initiallyExpanded,
    [styles['menu-item']]: true,
    [styles.expanded]:
      isItemGroup && (isSuggestedGroup ? isHighlightedExpanded : isExpanded),
  });

  const { pageData } = React.useContext(Context);

  const menuItemNode = (
    <LocalMenuItem
      isItemGroup={isItemGroup}
      initiallyExpanded={initiallyExpanded}
      isSelected={isSelected || isItemSelectedInGroup}
      isExpanded={isSuggestedGroup ? isHighlightedExpanded : isExpanded}
      channel={channel}
      name={name}
      details={details}
      duration={duration}
      priceRangeOutput={price}
      options2D={options2D}
      popoverShown={popoverShown}
      onShowDetails={(event: React.SyntheticEvent) => openServiceDetails(event)}
      onPopoverSubmit={(optionId: string) => handlePopoverSubmit(optionId)}
      onClick={() => {
        handleItemClick(
          pageData.venue.venue.status,
          isSelected!,
          isSuggestedGroup ? isHighlightedExpanded : isExpanded
        );
      }}
    />
  );

  return (
    <div
      className={menuItemStyle}
      key={serviceId}
      data-entity-id={id}
      data-cy="menu-item"
    >
      {menuItemNode}
      {itemGroupItems ? (
        <Stack space="sm" className={styles.options}>
          {itemGroupItems}
        </Stack>
      ) : (
        false
      )}
    </div>
  );
}
