import { getWindowDeviceType } from 'js/helpers/dom';
import React from 'react';
import { InView } from 'react-intersection-observer';

function isOnWifiOrAbove(): boolean {
  const connection = navigator.connection;

  if (!connection) {
    return false;
  }

  if (!connection.type) {
    return true;
  }

  return connection.type !== 'cellular';
}

interface Props {
  /**
   * offscreenPreloadMargin specifies a buffer zone around the placeholder, so that children are added to
   * the dom before they come into view. This way the user will not see elements loading while scrolling.
   * When undefined a default buffer zone of 1 window height is applied on desktop and one
   * of 3 window heights on mobile.
   */
  offscreenPreloadMargin?: string;
  /**
   * A placeholder of the same dimensions as the children has to be set, to allow for intersection
   * observation with the viewport.
   */
  placeholder: React.ReactNode;
  children: React.ReactNode;
}

interface DefaultProps {
  isAlwaysShowOnWifi: boolean;
}

interface State {
  isOnWifiOrAbove: boolean;
  windowDeviceType: undefined | 'server' | 'mobile' | 'tablet' | 'desktop';
}

export class WhenVisible extends React.PureComponent<
  Props & DefaultProps,
  State
> {
  public static readonly defaultProps: Readonly<DefaultProps> = {
    isAlwaysShowOnWifi: false,
  };

  public state: State = {
    isOnWifiOrAbove: false,
    windowDeviceType: undefined,
  };

  public componentDidMount(): void {
    this.setState({
      isOnWifiOrAbove: isOnWifiOrAbove(),
      windowDeviceType: getWindowDeviceType(),
    });

    window.addEventListener('resize', this.onResize);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  private onResize = () => {
    this.setState({
      windowDeviceType: getWindowDeviceType(),
    });
  };

  public render(): React.ReactNode {
    if (this.props.isAlwaysShowOnWifi && this.state.isOnWifiOrAbove) {
      return this.props.children;
    }

    // prettier-ignore
    let offscreenPreloadMargin: string | undefined = this.props.offscreenPreloadMargin;
    if (
      offscreenPreloadMargin === undefined &&
      this.state.windowDeviceType !== undefined
    ) {
      offscreenPreloadMargin =
        this.state.windowDeviceType === 'mobile' ? '300%' : '100%';
    }

    return (
      <InView rootMargin={offscreenPreloadMargin} triggerOnce>
        {({ inView, ref }) => {
          return (
            <div ref={ref}>
              {inView ? this.props.children : this.props.placeholder}
            </div>
          );
        }}
      </InView>
    );
  }
}
