import React from 'react';
import { Modal, ModalProps, Text } from '@treatwell/ui';
import { TeamListItemPortfolioImage } from '../TeamListItemPortfolioSection';
import { Gallery } from './Gallery/Gallery';
import styles from './TeamListItemPortfolioPopup.module.css';

type ModalPropsWithoutChildren = Omit<ModalProps, 'children'>;

interface Props extends ModalPropsWithoutChildren {
  initiallySelectedImage: TeamListItemPortfolioImage;
  images: TeamListItemPortfolioImage[];
  imageCountLabel: string;
  closeLabel: string;
}

export function TeamListItemPortfolioPopup(props: Props): React.ReactElement {
  const { initiallySelectedImage, images, closeLabel, ...modalProps } = props;
  const [selectedImage, setSelectedImage] =
    React.useState<TeamListItemPortfolioImage>(initiallySelectedImage);
  const modalContentRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!modalContentRef.current) {
      modalContentRef.current =
        document.querySelector<HTMLDivElement>('[id="modalBody"]');
    }

    // Scroll popup's content to top after selected image has changed.
    function scrollToTop(): void {
      requestAnimationFrame(() => {
        if (
          modalContentRef.current &&
          modalContentRef.current.scrollTop !== 0
        ) {
          modalContentRef.current.scroll({ top: 0, behavior: 'smooth' });
        }
      });
    }

    scrollToTop();
  }, [selectedImage]);

  return (
    <Modal {...modalProps}>
      <Modal.Header closeLabel={closeLabel}>
        <Text type="mdHeader" id="modalTitle">
          {modalProps.title}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.imageContainer}>
          <div
            key={selectedImage['800x1066']}
            className={styles.imageAspectRatioWrapper}
          >
            <img
              alt=""
              className={styles.image}
              src={selectedImage['800x1066']}
              width="800"
              height="1066"
            />
          </div>
        </div>
      </Modal.Body>
      {images.length > 1 && (
        <Modal.Footer>
          <Gallery
            images={images}
            selectedImage={selectedImage}
            onImageClick={(image) => {
              setSelectedImage(image);
            }}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
}
