import { mParticleEventType, mParticleLogEvent } from '@treatwell/ui';
import { VenueTagData } from 'js/components/VenueTag/VenueTag';
import { trackStructuredEvent } from 'js/helpers/google-tag-manager';
import { VenueRating } from 'js/model/rainbow/venue/VenueOutput';
import { MenuHighlight } from './BrowseResult';

export function trackBestTreatmentsBadgeViewed(
  venueId: number,
  trackingId: number
): void {
  trackStructuredEvent({
    category: 'browse-card',
    property: 'best_treatment_in_town',
    action: 'view',
    label: venueId.toString(),
    value: trackingId.toString(),
  });
}

export const getPrices = (
  menuHighlights?: MenuHighlight[]
): string | undefined => {
  if (!menuHighlights) {
    return;
  }
  return menuHighlights
    .map((item) => item.priceRangeOutput.minSalePriceAmount)
    .join(',');
};

export const getAverageDuration = (
  menuHighlights?: MenuHighlight[]
): string | undefined => {
  if (!menuHighlights) {
    return;
  }
  return menuHighlights
    .map((item) => {
      return (
        (item.durationRange.maxDurationMinutes +
          item.durationRange.minDurationMinutes) /
        2
      ).toFixed();
    })
    .join(',');
};

export const getResultData = (
  venueId: number,
  countryCode: string,
  pageName?: string,
  venueRank?: number,
  menuHighlights?: MenuHighlight[],
  rating?: VenueRating,
  tags?: VenueTagData[],
  isNewVenue?: boolean,
  displayedAccolade?: string,
  isVenueCardExpanded?: boolean,
  venueCardType?: string
): {} => {
  return {
    country: countryCode,
    platform: 'web',
    search_rank: venueRank,
    venue_id: venueId,
    venue_rating_displayed: rating?.displayAverage,
    venue_reviews_amount_displayed: rating?.count,
    treatment_ids_displayed:
      menuHighlights &&
      menuHighlights.map((item) => item.dataOfferId).join(','),
    treatment_avg_duration: getAverageDuration(menuHighlights),
    treatments_prices_displayed: getPrices(menuHighlights),
    venue_is_new_displayed: isNewVenue,
    venue_is_discounted_displayed: tags?.some(
      (tag) => tag.type === 'yieldDiscount'
    ),
    venue_accolade_image_name_displayed: displayedAccolade,
    is_venue_card_expanded: isVenueCardExpanded,
    page_name: pageName,
    venue_card_type: venueCardType,
  };
};

export async function trackVenueResultData({
  eventName,
  menuHighlights,
  venueId,
  rating,
  tags,
  countryCode,
  venueRank,
  isNewVenue,
  displayedAccolade,
  isVenueCardExpanded,
  pageName,
  venueCardType,
}: {
  eventName: string;
  menuHighlights?: MenuHighlight[];
  venueId: number;
  rating?: VenueRating;
  tags?: VenueTagData[];
  countryCode: string;
  venueRank?: number;
  isNewVenue?: boolean;
  displayedAccolade?: string;
  isVenueCardExpanded?: boolean;
  pageName?: string;
  venueCardType?: string;
}): Promise<void> {
  const eventData = getResultData(
    venueId,
    countryCode,
    pageName,
    venueRank,
    menuHighlights,
    rating,
    tags,
    isNewVenue,
    displayedAccolade,
    isVenueCardExpanded,
    venueCardType
  );
  await mParticleLogEvent(eventName, mParticleEventType.Other, eventData);
}
