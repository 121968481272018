import { httpGetJson, httpPost } from 'js/common/http';
import { cachifyPromise } from 'cachify-promise';

const flipperApiHost =
  process.env.FLIPPER_API_URI || 'flipper-server.dev01.k8s.dev.twcloud.io';
export const flipperApiUrl = `http://${flipperApiHost}/flipper-server/v1/flag`;
export const flipperClientApiUrl = `http://${flipperApiHost}/flipper-client/v1/evaluate/flag`;

export const flipperClientFlags = {
  VenueHeaderExperiment: 'enhance-venue-page-navigation',
  BrowseRelatedTreatments: 'experiment_browse-related-treatments',
} as const;

type FlipperClientFlagValue =
  (typeof flipperClientFlags)[keyof typeof flipperClientFlags];

export type FlipperServerResponse = {
  enabled: boolean;
  selectors: Array<{ key: string; values: [string] }>;
};

type FlipperClientResponse = {
  result: Record<FlipperClientFlagValue, boolean>;
};

const DEV = 'development';
const nodeEnv = process.env.NODE_ENV || DEV;
const isEnvDevelopment = nodeEnv === DEV;

export const fetchFlipperFlag = cachifyPromise(
  (flagName: string, ruid: string) => {
    // requestHeaders should be an empty object otherwise
    // the request 404s
    return httpGetJson<FlipperServerResponse>(`${flipperApiUrl}/${flagName}`, {
      ruid,
      requestHeaders: {},
    });
  },
  {
    ttl: 3000 * 60,
    debug: isEnvDevelopment,
    cacheKeyFn: (flagName: string) => `${flipperApiUrl}/${flagName}`,
  }
);

type FlipperClientBodyType = {
  uniqueUserId?: string;
  customAttributes: Record<string, string>;
};

export const fetchFlipperClientFlag = (
  flagName: FlipperClientFlagValue,
  ruid: string,
  body: FlipperClientBodyType
) => {
  // requestHeaders should be an empty object otherwise
  // the request 404s

  return httpPost<FlipperClientResponse>(`${flipperClientApiUrl}/${flagName}`, {
    ruid,
    requestHeaders: {},
    data: body,
    isTimeoutEnabled: true,
  });
};
