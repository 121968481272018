/**
 * Format one of multiple simple templates, selecting the template based on a number.
 *
 * The template is a comma separated list of choices.
 * Each choice has two parts colon separated parts; , a range (to match the number against) and a template
 *     1-2:# things
 *
 * The first choice with a range that matches the passed number is used.
 *
 * The range part consists of two "-" separated numbers. One or both number may be present
 *     3      exactly 3
 *     2-5    2 to 5 inclusive
 *     -4     less than or equal to 4
 *     7-     7 or greater
 *
 * The template for a choice is a string, with an optional #.
 * The # is a placeholder where the number may be substituted.
 *
 * A slightly crazy example of a template string.
 *     "-2:# widget,3:# widgetz,4-5:# widgetum,7-:# widgets"
 *
 * @param template    string    the templates, and conditions for matching
 * @param entityCount    Number  the number to use for matching with a template.
 */
export function choiceFormatter(template: string, entityCount: number): string {
  const choices = template.split(',').map((value: string): Choice => {
    const parts: string[] = value.split(':');

    if (parts.length !== 2) {
      console.warn('Skipping invalid choice part', value);
      return { template: value };
    }

    const range = parts[0];
    const template = parts[1];
    const rangeParts = range.split('-');
    let lower;
    let upper;

    switch (rangeParts.length) {
      case 1:
        if (rangeParts[0]) {
          lower = parseInt(rangeParts[0], 10);
          upper = lower;
        }
        break;
      case 2:
        lower = parseInt(rangeParts[0], 10);
        upper = parseInt(rangeParts[1], 10);
        break;
      default:
        console.warn('Invalid range', range);
        break;
    }

    return {
      lower,
      upper,
      template,
    };
  });

  interface Choice {
    lower?: number;
    upper?: number;
    template: string;
  }

  const matchingChoice = choices.filter((choice: Choice) => {
    if (choice.lower !== undefined && entityCount < choice.lower) {
      return false;
    }

    if (choice.upper !== undefined && entityCount > choice.upper) {
      return false;
    }

    return true;
  });

  if (matchingChoice.length > 0) {
    return matchingChoice[0].template.replace('#', entityCount.toString());
  }
  console.warn(`No matching choice for ${entityCount} in template`);
  return '';
}
