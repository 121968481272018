/* eslint-disable array-callback-return */
import React from 'react';
import {
  Stack,
  Inline,
  Text,
  Modal,
  useModal,
  useViewport,
} from '@treatwell/ui';
import { AmenityGroup, Amenity } from 'js/model/rainbow/venue/AmenityGroup';
import { PageSection } from 'js/components/PageSection';
import { Button, ButtonColour } from 'js/components/Button';
import { trackSeeMoreClicked } from './tracking';
import styles from './Amenities.module.css';

interface Props {
  amenityGroups?: AmenityGroup[];
  cms: Cms;
}

interface Cms {
  sectionTitle: string;
  buttonLabel(amenityCount: number): string;
  popupTitle: string;
  popupCloseText: string;
}

interface AmenityGroupRenderer {
  getRenderedAmenityCount(): number;
  render(): React.ReactElement;
}

export function Amenities(props: Props): React.ReactElement | null {
  const { amenityGroups, cms } = props;
  const isMobile = useViewport({ device: 'mobile' });
  const MAX_INITIALLY_SHOWN = 8;
  const amenityCount = getTotalNumberOfAmenities();
  const initiallyVisibleAmenities =
    amenityCount >= MAX_INITIALLY_SHOWN ? MAX_INITIALLY_SHOWN : amenityCount;
  const { openModal, modalProps } = useModal({
    title: cms.popupTitle,
    width: 763,
  });

  if (!amenityGroups || !amenityGroups.length) {
    return null;
  }

  function getAmenityGroupContentRenderer(
    amenityGroup: AmenityGroup,
    limit: number
  ): AmenityGroupRenderer {
    let renderedAmenityCount = 0;
    const renderedAmenityGroupContent = (
      <>
        <div className={styles.amenityGroupNameContainer}>
          <Text
            key="group-name"
            type="smHeader"
            className={styles.amenityGroupName}
          >
            {amenityGroup.name}
          </Text>
        </div>
        <div className={styles.amenities}>
          {amenityGroup.amenities.map((amenity, index) => {
            if (index < limit) {
              renderedAmenityCount++;
              return renderAmenity(amenity);
            }
          })}
        </div>
      </>
    );

    return {
      getRenderedAmenityCount: () => renderedAmenityCount,
      render: () => renderedAmenityGroupContent,
    };
  }

  function getAmenityGroupRenderer(
    amenityGroup: AmenityGroup,
    limit: number,
    shouldRenderInPopup?: boolean
  ): AmenityGroupRenderer {
    const { render: renderAmenityGroupContent, getRenderedAmenityCount } =
      getAmenityGroupContentRenderer(amenityGroup, limit);
    const renderedAmenityCount = getRenderedAmenityCount();

    const renderedAmenityGroup = (
      <div className={styles.amenityGroup} key={amenityGroup.id}>
        {isMobile || shouldRenderInPopup ? (
          <Stack space="xl">{renderAmenityGroupContent()}</Stack>
        ) : (
          <Inline align="start" justify="between">
            {renderAmenityGroupContent()}
          </Inline>
        )}
      </div>
    );

    return {
      getRenderedAmenityCount: () => renderedAmenityCount,
      render: () => renderedAmenityGroup,
    };
  }

  function renderAmenityGroups(
    shouldRenderInPopup?: boolean
  ): React.ReactElement {
    let limit = shouldRenderInPopup ? Number.MAX_VALUE : MAX_INITIALLY_SHOWN;

    return (
      <>
        {amenityGroups?.map((group) => {
          const amenityGroup = getAmenityGroupRenderer(
            group,
            limit,
            shouldRenderInPopup
          );
          const numberOfRenderedAmenities =
            amenityGroup.getRenderedAmenityCount();
          limit -= numberOfRenderedAmenities;
          if (shouldRenderInPopup) {
            return amenityGroup.render();
          }
          if (
            (numberOfRenderedAmenities < MAX_INITIALLY_SHOWN && limit !== 0) ||
            limit < numberOfRenderedAmenities
          ) {
            return amenityGroup.render();
          }
        })}
      </>
    );
  }

  function renderAmenity(amenity: Amenity): React.ReactNode {
    return (
      <Text key={amenity.id} as="div" className={styles.amenity}>
        {amenity.name}
      </Text>
    );
  }

  function getTotalNumberOfAmenities(): number {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return amenityGroups?.reduce((a, b) => a + b.amenities.length, 0)!;
  }

  return (
    <PageSection id="amenities" title={cms.sectionTitle}>
      <div>
        <div>{renderAmenityGroups()}</div>
        {amenityCount > MAX_INITIALLY_SHOWN && (
          <Inline align="end" justify="center" className={styles.fold}>
            <Button
              positioningClassname={styles.showMoreButton}
              colour={ButtonColour.BlueOutline}
              label={cms.buttonLabel(amenityCount)}
              onClick={() => {
                openModal();
                trackSeeMoreClicked(
                  initiallyVisibleAmenities.toString(),
                  amenityCount
                );
              }}
            />
          </Inline>
        )}
        <Modal {...modalProps}>
          <Modal.Header closeLabel={cms.popupCloseText}>
            <Text type="smHeader">{cms.popupTitle}</Text>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.amenitiesPopupContent}>
              {renderAmenityGroups(true)}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </PageSection>
  );
}
