import React from 'react';

interface Props {
  color: string;
}

export const IconNails: React.FunctionComponent<Props> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1C10.1046 1 11 1.89543 11 3V10H9V15.275L9.32781 15.378C10.4085 15.712 11.1795 15.8876 12.0007 15.9597L12 14C12 13.4872 11.614 13.0645 11.1166 13.0067L11 13H10V11H11C12.6569 11 14 12.3431 14 14V23H2V14C2 12.3431 3.34315 11 5 11H6V13H5C4.48716 13 4.06449 13.386 4.00673 13.8834L4 14L4.00148 14.033C4.97169 14.1017 5.84215 14.2838 6.99956 14.6309L7 10H5V3C5 1.89543 5.89543 1 7 1H9ZM19 1C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23C17.3431 23 16 21.6569 16 20V4C16 2.34315 17.3431 1 19 1ZM4.00031 16.0404L4 21H12L11.9995 17.9671C11.0293 17.8984 10.1589 17.7165 9.00167 17.3695L9 20H7V16.724L6.67231 16.622C5.592 16.2881 4.82117 16.1125 4.00031 16.0404ZM19 3C18.4872 3 18.0645 3.38604 18.0067 3.88338L18 4V20C18 20.5523 18.4477 21 19 21C19.5128 21 19.9355 20.614 19.9933 20.1166L20 20V4C20 3.44772 19.5523 3 19 3ZM9 3H7V8H9V3Z"
      fill={props.color}
    />
  </svg>
);
