import * as React from 'react';
import {
  SectionData,
  SectionDataItem,
} from 'js/components/controls/SelectInput/SectionData';
import {
  AssociatedDataSelectInput,
  AssociatedDataSelectInputHandle,
} from 'js/components/controls/AssociatedDataSelectInput';
import { CmsCommonControls } from 'js/model/cms/cms-common-controls';
import { ClearableMode } from './DropdownInput/DropdownInput';

interface Props<T> {
  cmsCommonControls: CmsCommonControls;
  icon: React.ComponentType<{ positioningClassName?: string; error?: boolean }>;
  placeholder: string;
  searchResultsHeading: string;
  dataForNoSearch: SectionData<T>[];
  dataAlwaysInDropdown?: SectionData<T>[];
  searchFunction: (value: string) => Promise<SectionDataItem<T>[]>;
  isErrorStyling: boolean;
  isClearable?: ClearableMode;
  isFocusOnClear?: boolean;
  isHotJarWhiteList?: boolean;
  noResultsData?: SectionData<T>[];
  isPatternedBackground?: boolean;
  renderHiddenResults?: boolean;

  // called when the text fields value changed
  // value: the value that was typed into the field or the items label
  // selectedKey: null or the selected item key when change occurred due to
  //   selection (cursor navigation) of a list item
  onChange?: (
    value: string,
    selectedKey: string | null,
    data: T | null,
    index: number
  ) => void;
  onComplete?: () => void;

  onFocus?: () => void;
  onBlur?: () => void;
}

export interface CommonSearchInputHandle {
  clear: () => void;
  setSelectedItemKey: (selectedItemKey: string) => void;
}

function CommonSearchInputInner<T>(
  { renderHiddenResults = false, ...props }: Props<T>,
  ref: React.Ref<CommonSearchInputHandle>
) {
  const isDestroyed = React.useRef<boolean>(false);
  const inputRef = React.useRef<AssociatedDataSelectInputHandle>(null);
  const [data, setData] = React.useState<SectionData<T>[] | null>(null);

  React.useEffect(() => {
    return () => {
      isDestroyed.current = true;
    };
  }, []);

  React.useImperativeHandle(ref, () => ({
    clear(): void {
      if (inputRef.current === null) {
        return;
      }

      inputRef.current.setSelectedItemKey(null);
    },
    setSelectedItemKey(selectedItemKey: string): void {
      if (inputRef.current === null) {
        return;
      }

      inputRef.current.setSelectedItemKey(selectedItemKey);
    },
  }));

  function processChange(value: string, selectedKey: string | null): void {
    if (value.length === 0 || (value.length < 3 && selectedKey === null)) {
      setData(null);
      return;
    }

    if (selectedKey !== null) {
      return;
    }

    props.searchFunction(value).then((items: SectionDataItem<T>[]) => {
      if (isDestroyed.current) {
        return;
      }

      if (items.length > 0) {
        setData([
          {
            heading: props.searchResultsHeading,
            items,
          },
        ]);
      } else {
        setData(getNoResultData());
      }
    });
  }

  function getNoResultData(): SectionData<T>[] {
    if (props.noResultsData) {
      return props.noResultsData;
    }

    return [
      {
        items: [
          {
            type: 'label',
            label: props.cmsCommonControls.search['no-search-results-label'],
          },
        ],
      },
    ];
  }

  function onChange(
    value: string,
    selectedKey: string | null,
    data: T | null,
    index: number
  ) {
    processChange(value, selectedKey);

    if (props.onChange) {
      props.onChange(value, selectedKey, data, index);
    }
  }

  const { cmsCommonControls } = props;

  const sectionData: SectionData<T>[] = [];

  if (props.dataAlwaysInDropdown) {
    sectionData.push(...props.dataAlwaysInDropdown);
  }

  if (data !== null) {
    sectionData.push(...data);
  } else {
    sectionData.push(...props.dataForNoSearch);
  }
  return (
    <AssociatedDataSelectInput
      ref={inputRef}
      icon={props.icon}
      closeButtonText={cmsCommonControls['dropdown-input']['close-button']}
      isErrorStyling={props.isErrorStyling}
      isClearable={props.isClearable}
      isHotJarWhiteList={props.isHotJarWhiteList}
      isFocusOnClear={props.isFocusOnClear}
      placeholder={props.placeholder}
      sectionData={sectionData}
      onChange={onChange}
      onComplete={props.onComplete}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      renderHiddenResults={renderHiddenResults}
      isPatternedBackground={props.isPatternedBackground}
    />
  );
}

export const CommonSearchInput = React.forwardRef(CommonSearchInputInner) as <
  T,
>(
  props: Props<T> & { ref?: React.ForwardedRef<CommonSearchInputHandle> }
) => ReturnType<typeof CommonSearchInputInner>;

// @ts-ignore
CommonSearchInput.displayName = 'CommonSearchInput';
