import React from 'react';
import {
  ChangeBrowseDataFunction,
  changeBrowseDataContext,
  AddLocation,
} from 'js/pages/BrowsePage/BrowseData';
import { DistanceOutput } from 'js/model/rainbow/DistanceOutput';

import listItemStyles from 'js/components/controls/SelectInput/ListItem.module.css';
import { channelContext } from 'js/components/channel';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import {
  trackLocationRadiusSelect,
  Category,
  wwwTrackingContext,
} from 'js/pages/BrowsePage/WhatWhereWhen/tracking';
import styles from './LocationRadius.module.css';

interface ContainerProps {
  radius?: DistanceOutput;
  validRadius: number[];
  label: string;
  nearbyLabel: string;
  closeDropdown: () => void;
}

interface Props extends ContainerProps {
  channel: ChannelOutput;
  changeBrowseData: ChangeBrowseDataFunction;
  trackingCategory: Category;
}

interface State {
  value: string;
}

function formatRadiusForSelect(radius?: DistanceOutput): string {
  return radius ? String(radius.distance) : '';
}

function normaliseSelection(selection: string, channel: ChannelOutput): number {
  return parseFloat(selection.replace(channel.decimalSeparator, '.'));
}

class LocationRadiusOptions extends React.Component<
  Pick<Props, 'channel' | 'validRadius' | 'nearbyLabel'>
> {
  public render(): React.ReactNode {
    const options = this.props.validRadius.map((value) => {
      let unit = 'km';
      if (this.props.channel.distanceUnit === 'mile') {
        unit = value === 1 ? 'mile' : 'miles';
      }

      const displayValue = value
        .toString()
        .replace('.', this.props.channel.decimalSeparator);

      return { label: `${displayValue} ${unit}`, value };
    });

    return [{ label: this.props.nearbyLabel, value: 0 }]
      .concat(options)
      .map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ));
  }
}

class LocationRadius extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: formatRadiusForSelect(props.radius),
    };
  }

  public componentDidUpdate(): void {
    if (this.state.value !== formatRadiusForSelect(this.props.radius)) {
      this.setState({ value: formatRadiusForSelect(this.props.radius) });
    }
  }

  private onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ value: event.target.value });

    const radius = normaliseSelection(event.target.value, this.props.channel);

    trackLocationRadiusSelect(
      this.props.trackingCategory,
      this.props.channel.distanceUnit,
      radius
    );

    this.props.changeBrowseData(
      { location: { radius: radius || 0 } },
      [],
      AddLocation.CurrentPage
    );
    this.props.closeDropdown();
  };

  public render(): React.ReactNode {
    return (
      <div className={listItemStyles.listItem}>
        <label className={styles.label}>{this.props.label}</label>
        <select
          className={styles.select}
          value={this.state.value || ''}
          onChange={this.onChange}
        >
          <LocationRadiusOptions
            channel={this.props.channel}
            validRadius={this.props.validRadius}
            nearbyLabel={this.props.nearbyLabel}
          />
        </select>
      </div>
    );
  }
}

function LocationRadiusContainer(props: ContainerProps): React.ReactElement {
  return (
    <channelContext.Consumer>
      {(channel) => (
        <changeBrowseDataContext.Consumer>
          {(changeBrowseData) => (
            <wwwTrackingContext.Consumer>
              {(trackingCategory) => (
                <LocationRadius
                  {...props}
                  channel={channel}
                  changeBrowseData={changeBrowseData}
                  trackingCategory={trackingCategory}
                />
              )}
            </wwwTrackingContext.Consumer>
          )}
        </changeBrowseDataContext.Consumer>
      )}
    </channelContext.Consumer>
  );
}

export { LocationRadiusContainer as LocationRadius };
