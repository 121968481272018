import {
  EmployeePortfolioImage,
  ImageOutput,
} from 'js/model/rainbow/ImageOutput';

/*
  Get the nth ImageOutput from each array of ImageOutput.
  If an array is too short, skip it.
 */
function getColumn<T>(arrays: T[][], index: number): T[] {
  const items: T[] = [];

  for (const array of arrays) {
    if (index < array.length) {
      items.push(array[index]);
    }
  }

  return items;
}

/*
  Interleave an array of ImageOutput arrays.

  Takes
      1st image from 1st array
      1st image from 2nd array
      1st image from 3nd array
      ...
      2nd image from 1st array
      2nd image from 2nd array
      ...
      ...

  Skips items where arrays are too short.
 */
export function interleaveImages<
  T extends ImageOutput | EmployeePortfolioImage,
>(arrays: T[][]): T[] {
  const longestArrayLength = arrays.reduce(
    (prev, next) => Math.max(prev, next.length),
    0
  );

  // Interleave the items.
  const interleavedItems = [];
  for (let i = 0; i < longestArrayLength; i++) {
    interleavedItems.push(getColumn(arrays, i));
  }

  // flatten Image[][] to Image[]
  return interleavedItems.reduce((prev, next) => prev.concat(next), []);
}
