import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { ServicePriceRangeCms } from 'js/helpers/price/ServicePriceRangeCms';
import * as priceUtilities from './priceUtilities';

export class PriceRange {
  private priceRangeOutput: PriceRangeOutput;

  constructor(priceRangeOutput: PriceRangeOutput) {
    this.priceRangeOutput = priceRangeOutput;
  }

  public isRange(): boolean {
    return this.priceRangeOutput.range || false;
  }

  public getSalePrice(): string {
    return this.priceRangeOutput.minSalePriceAmount;
  }

  public getFullPrice(): string {
    return this.priceRangeOutput.minFullPriceAmount;
  }

  public getDiscount(): number {
    return this.priceRangeOutput.maxDiscountPercentage;
  }

  public hasYieldDiscount(): boolean {
    return this.priceRangeOutput.yieldDiscountTypes.length > 0;
  }

  /**
   * Should show strike through if price range data is not range and
   * minFullPriceAmount (strikeThroughPrice) is not minSalePriceAmount (price)
   */
  public shouldShowStrikeThrough(): boolean {
    const price: string = priceUtilities.amountToString(this.getSalePrice());
    const strikeThroughPrice = priceUtilities.amountToString(
      this.getFullPrice()
    );
    return !this.isRange() && price !== strikeThroughPrice;
  }

  /**
   * Checks 'should show strike through' condition for passed in PriceRange and returns
   * either formatted strike through price or an empty string.
   */
  public getStrikeThroughPrice(channelOutput: ChannelOutput): string {
    if (this.shouldShowStrikeThrough()) {
      return priceUtilities.formatPrice(this.getFullPrice(), channelOutput);
    }
    return '';
  }

  /**
   * Checks if PriceRange has discount and returns full discount text if true
   * or empty string if false.
   */
  public getDiscountText(servicePriceRangeCms: ServicePriceRangeCms): string {
    const discountText = this.isRange()
      ? servicePriceRangeCms.saveUpTo
      : servicePriceRangeCms.save;
    const discount: string = this.getDiscount().toString();
    return this.hasYieldDiscount() && !this.shouldShowStrikeThrough()
      ? `${discountText} ${discount}%`
      : '';
  }

  /**
   * Checks if conditions in PriceRange are met to add a prefix to the formatted price
   * and returns either the formatted price with prefix or without.
   * This is used in MenuGroupHeader.
   */
  public getPriceWithPrefix(
    channelOutput: ChannelOutput,
    servicePriceRangeCms: ServicePriceRangeCms
  ): string {
    const price = priceUtilities.formatPrice(
      this.getSalePrice(),
      channelOutput
    );
    const prefix = priceUtilities.getPricePrefix(this, servicePriceRangeCms);
    return prefix === '' ? price : `${prefix} ${price}`;
  }

  public static createDefaultPriceRangeOutput(
    json: object | null = null
  ): PriceRangeOutput {
    const priceRangeOutput: PriceRangeOutput = {
      maxDiscountPercentage: 0,
      maxFullPriceAmount: '',
      maxSalePriceAmount: '',
      maxSavingAmount: '',
      minDiscountPercentage: 0,
      minFullPriceAmount: '',
      minSalePriceAmount: '',
      minSavingAmount: '',
      range: false,
      yieldDiscountTypes: [],
    } as unknown as PriceRangeOutput;
    if (json) {
      Object.assign(priceRangeOutput, json);
    }
    return priceRangeOutput;
  }
}
