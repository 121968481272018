import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  Tooltip,
  TooltipPosition,
  TooltipPointerPosition,
} from 'js/components/generic/Tooltip';
import styles from './styles.module.css';

interface Props {
  tooltipText: string;
  imageUri: string;
  size: AccoladeBadgeSize;
  tooltipPosition?: TooltipPosition; // when not specified automatically determine tooltip position based on window width
  positioningClass?: string;
}

export enum AccoladeBadgeSize {
  Small = 'small',
  Large = 'large',
}

export class AccoladeBadge extends React.Component<
  Props,
  { showTooltip: boolean; isBrowser: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showTooltip: false,
      isBrowser: false,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('scroll', this.hideTooltip);
    this.setState({ isBrowser: true });
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.hideTooltip);
  }

  private onClick = (event: React.MouseEvent): void => {
    // An accolade may be used inside an <a>. In which case clicking
    // to toggle the toolip should not follow the anchor's href.
    event.preventDefault();
    event.stopPropagation();
    this.setState((state) => ({ showTooltip: !state.showTooltip }));
  };

  private showTooltip = (): void => {
    this.setState({ showTooltip: true });
  };

  private hideTooltip = (): void => {
    this.setState({ showTooltip: false });
  };

  public render(): ReactNode {
    const { tooltipText, imageUri, size } = this.props;
    const styleClasses = clsx(
      styles.accoladeBadge,
      styles[size],
      this.props.positioningClass
    );

    let position: TooltipPosition;
    if (this.props.tooltipPosition === undefined) {
      position =
        this.state.isBrowser && window.innerWidth < 440
          ? TooltipPosition.Bottom
          : TooltipPosition.Left;
    } else {
      position = this.props.tooltipPosition;
    }

    return (
      <div
        className={styleClasses}
        onClick={this.onClick}
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        style={{ backgroundImage: `url(${imageUri})` }}
      >
        {this.state.showTooltip ? (
          <Tooltip
            position={position}
            pointer={TooltipPointerPosition.Middle}
            extraStyles={[styles.tooltip]}
          >
            {tooltipText}
          </Tooltip>
        ) : null}
      </div>
    );
  }
}
