import * as React from 'react';
import {
  SectionData,
  SectionDataItem,
} from 'js/components/controls/SelectInput/SectionData';
import {
  SelectInput,
  SelectInputProps as AllSelectInputProps,
  SelectInputHandle,
} from '../SelectInput';
import { ItemResultType } from '../search-item-result';

type SelectInputProps = Omit<AllSelectInputProps, 'onChange' | 'sectionData'>;

interface Props<T> extends SelectInputProps {
  // called when the text fields value changed
  // value: the value that was typed into the field or the items label
  // selectedKey: null or the selected item key when change occurred due to
  //   selection (cursor navigation) of a list item
  // data: null or the data of the matching or selected item
  onChange?: (
    value: string,
    selectedKey: string | null,
    data: T | null,
    index: number
  ) => void;

  sectionData: SectionData<T>[];
  isPatternedBackground?: boolean;
}

export interface AssociatedDataSelectInputHandle {
  setSelectedItemKey: (selectedItemKey: string | null) => void;
}

export function getItemPosition(
  sectionData: SectionData<unknown>[],
  selectedKey: string
): number {
  let index = 1;
  let counter = 1;
  sectionData.forEach((element) => {
    element.items.forEach((item) => {
      if (
        item.type === ItemResultType.TreatmentType ||
        item.type === ItemResultType.Treatments ||
        item.type === ItemResultType.Location ||
        item.type === ItemResultType.PostalArea ||
        item.type === ItemResultType.PostalReference ||
        item.type === ItemResultType.Venue ||
        item.type === ItemResultType.VenueType
      ) {
        if (item.key === selectedKey) {
          index = counter;
        }
        counter++;
      }
    });
  });
  return index;
}

/*
 * AssociatedDataSelectInputInner composes SelectInput. The API is the same as SelectInput.
 * Although this component provides one additional `data` property to `sectionData`'s `items`.
 * This additional data property is returned with the onChange event. Therefore allowing list items to
 * have associated structured data and not just a key for identification.
 */
function AssociatedDataSelectInputInner<T>(
  props: Props<T>,
  ref: React.Ref<AssociatedDataSelectInputHandle>
): React.ReactElement {
  const selectInputRef = React.useRef<SelectInputHandle>(null);

  React.useImperativeHandle(ref, () => ({
    setSelectedItemKey(selectedItemKey: string | null): void {
      if (selectInputRef.current === null) {
        return;
      }

      selectInputRef.current.setSelectedItemKey(selectedItemKey);
    },
  }));

  function findFirstItemWithLabel<T>(
    sectionData: SectionData<T>[],
    label: string
  ): SectionDataItem<T> | null {
    for (const section of sectionData) {
      for (const item of section.items) {
        if (item.label.toLowerCase() === label.toLowerCase()) {
          return item;
        }
      }
    }

    return null;
  }

  function findFirstItemWithKey<T>(
    sectionData: SectionData<T>[],
    key: string
  ): SectionDataItem<T> | null {
    for (const section of sectionData) {
      for (const item of section.items) {
        if ('key' in item && item.key === key) {
          return item;
        }
      }
    }

    return null;
  }

  function findDataForKeyOrValue(key: string | null, value: string): T | null {
    if (!props.sectionData) {
      return null;
    }

    if (key !== null) {
      const item = findFirstItemWithKey(props.sectionData, key);
      if (item) {
        if ('data' in item && item.data !== undefined) {
          return item.data;
        }
        return null;
      }
    }

    const item = findFirstItemWithLabel(props.sectionData, value);
    if (item) {
      if ('data' in item && item.data !== undefined) {
        return item.data;
      }
      return null;
    }

    return null;
  }

  function onChange(value: string, selectedKey: string | null): void {
    if (!props.onChange) {
      return;
    }
    const index = getItemPosition(props.sectionData, selectedKey!);
    props.onChange(
      value,
      selectedKey,
      findDataForKeyOrValue(selectedKey, value),
      index
    );
  }

  return (
    <SelectInput
      {...props}
      onChange={onChange}
      ref={selectInputRef}
      isPatternedBackground={props.isPatternedBackground}
    />
  );
}

export const AssociatedDataSelectInput = React.forwardRef(
  AssociatedDataSelectInputInner
) as <T>(
  props: Props<T> & {
    ref?: React.ForwardedRef<AssociatedDataSelectInputHandle>;
  }
) => ReturnType<typeof AssociatedDataSelectInputInner>;

// @ts-ignore
AssociatedDataSelectInput.displayName = 'AssociatedDataSelectInput';
