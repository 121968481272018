import React, { lazy, Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { trackServiceDetailsEvent } from 'js/pages/VenuePage/tracking';
import { getFragmentValue } from 'js/helpers/uri-util';
import { getItemByServiceId } from 'js/helpers/venue-menu';

import { getVenuePage } from 'js/redux-modules/venue-page';
import {
  openServicePopup,
  closeServicePopup,
} from 'js/redux-modules/venue-page/popup';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { StateData } from 'js/model/rainbow/StateData';

export interface VenuePage {
  servicePopup: { serviceId: string };
}

type Props = PropsFromRedux & {
  openServicePopup: (serviceId: string) => void;
  closeServicePopup: () => void;
  venuePage: VenuePage;
  isWidget?: boolean;
  serviceImages?: ImageOutput[];
  pageData: StateData;
};

const ServicePopup = lazy(
  () =>
    import(
      /* webpackChunkName: "ServicePopup" */ '../../../components/ServicePopup/ServicePopup'
    )
);

function PopupsSectionComponent({
  openServicePopup,
  closeServicePopup,
  venuePage,
  isWidget,
  serviceImages,
  pageData,
}: Props): React.JSX.Element | null {
  React.useEffect(() => {
    openServiceDetailsFromUri();
    window.addEventListener('popstate', onPopState);
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    });
  }, []);

  const venuePageData = pageData.venue;

  function openServiceDetailsFromUri(): void {
    const serviceId = getFragmentValue('serviceDetails');
    const { menuGroups } = venuePageData.venue.menu;
    const numericServiceId = serviceId?.substring(2);

    if (!serviceId) {
      return;
    }

    if (!getItemByServiceId(numericServiceId, menuGroups).item) {
      // The service is not in the menu. Perhaps the user bookmarked a url
      // with a service details popup, and the service is no longer available.
      window.location.hash = '';
      return;
    }
    openServicePopup(serviceId);
    trackServiceDetailsEvent(
      getItemByServiceId(numericServiceId, menuGroups).item!
    );
  }

  function onPopState(event: PopStateEvent): void {
    const { serviceDetails } = event.state || {};

    if (serviceDetails) {
      openServiceDetailsFromUri();
    } else {
      closeServicePopup();
    }
  }

  const venue = venuePageData.venue;
  const channel = pageData.channel;

  const { servicePopup } = venuePage;

  const menuGroups = venuePageData.venue.menu.menuGroups;
  let groupId;
  let serviceDetails;
  if (servicePopup) {
    const numericServiceId = servicePopup.serviceId.substring(2);
    const item = getItemByServiceId(numericServiceId, menuGroups);
    if (item && item.item) {
      groupId = item.menuGroup?.id;
      serviceDetails = item.item.data;
    }
  }

  return (
    servicePopup && (
      <Suspense>
        <ServicePopup
          serviceId={servicePopup.serviceId}
          groupId={groupId?.toString()}
          menuServiceDetails={serviceDetails}
          venue={venue}
          channel={channel}
          onClose={closeServicePopup}
          showRating={!isWidget}
          showReviews={!isWidget}
          showTreatmentGuideLink={!isWidget}
          serviceImages={serviceImages}
        />
      </Suspense>
    )
  );
}

const mapStateToProps = (state: VenuePage) => ({
  venuePage: getVenuePage(state),
});

const actions = {
  openServicePopup,
  closeServicePopup,
};

const connector = connect(mapStateToProps, actions);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const PopupsSection = connector(PopupsSectionComponent);
