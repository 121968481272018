import React, { ComponentProps, MouseEventHandler, useState } from 'react';
import {
  Inline,
  Text,
  Viewport,
  TreatsLogo,
  MediaObject,
  LoyaltyInfoModal,
} from '@treatwell/ui';
import { CmsPageVenue } from 'js/model/cms/cms-venue-page';
import { substituteWithDictionary } from 'js/helpers/i18n';
import { ChannelLoyalty } from 'js/model/rainbow/content/ChannelOutput';
import { formatPrice } from 'js/helpers/price/priceUtilities';
import { getAchievements } from 'js/components/Loyalty/utils/getAchievements';
import clonedeep from 'lodash.clonedeep';
import styles from './LoyaltyTotal.module.css';

type LoyaltyInfoModalProps = ComponentProps<typeof LoyaltyInfoModal>;

type Props = {
  contentText: string;
  onLogoClick: MouseEventHandler<HTMLButtonElement>;
};

export const LoyaltyTotal = ({ contentText, onLogoClick }: Props) => {
  return (
    <div className={styles.loyaltyTotal}>
      <Viewport device="desktop">
        <MediaObject align="center" justify="between" space="sm">
          <button
            type="button"
            className={styles.logoButton}
            onClick={onLogoClick}
          >
            <TreatsLogo />
          </button>
          <Text type="footnoteHeavy">{contentText}</Text>
        </MediaObject>
      </Viewport>
      <Viewport device={['mobile', 'tablet']}>
        <Inline space="xs">
          <button
            type="button"
            className={styles.logoButton}
            onClick={onLogoClick}
          >
            <TreatsLogo />
          </button>
          <Text type="footnote" aria-hidden>
            |
          </Text>
          <Text type="footnoteHeavy">{contentText}</Text>
        </Inline>
      </Viewport>
    </div>
  );
};

export type ContainerProps = {
  cms: CmsPageVenue;
  channel: ChannelLoyalty;
  total: number;
};

export const LoyaltyTotalContainer = ({
  cms,
  channel,
  total,
}: ContainerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { loyalty } = channel;

  // Deep copy CMS strings so we can mutate them
  const modalStrings = clonedeep(cms['vc-bar']['loyalty-modal']);

  const { highestAchievementValue, lowestAchievementValue } = getAchievements({
    channel,
    achievements: loyalty.achievements,
  });

  const { earnRateDenominator, earnRateNumerator } = loyalty.challenges.order;
  const earnRate = formatPrice(earnRateNumerator, channel);

  const labels: LoyaltyInfoModalProps['labels'] = { ...modalStrings };

  // Substitute string values
  labels.instructionSteps.earn.description = (
    <span
      dangerouslySetInnerHTML={{
        __html: substituteWithDictionary(
          modalStrings.instructionSteps.earn.description,
          { count: earnRateDenominator.toString(), earnRate }
        ),
      }}
    />
  );

  labels.instructionSteps.redeem.description = (
    <span
      dangerouslySetInnerHTML={{
        __html: substituteWithDictionary(
          modalStrings.instructionSteps.redeem.description,
          { lowestAchievementValue, highestAchievementValue }
        ),
      }}
    />
  );

  labels.title = (
    <Text type="mdHeader">
      {substituteWithDictionary(modalStrings.title, {
        highestAchievementValue,
      })}
    </Text>
  );

  return (
    <>
      <LoyaltyInfoModal
        labels={labels}
        isOpen={isOpen}
        onToggle={(state) => setIsOpen(state)}
        onCTAClick={() => setIsOpen(false)}
      />
      <LoyaltyTotal
        contentText={substituteWithDictionary(cms['vc-bar']['loyalty-total'], {
          count: total.toString(),
        })}
        onLogoClick={() => setIsOpen(true)}
      />
    </>
  );
};
