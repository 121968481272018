import React from 'react';
import clsx from 'clsx';
import { Message, Stack, Text } from '@treatwell/ui';
import { useWindowSize } from 'js/hooks/useWindowSize';
import { splitToParagraphs } from 'js/helpers/text';
import { CmsOurWork } from 'js/model/cms/cms-venue-page';
import styles from './PatchTestWarning.module.css';

interface Props {
  cms: Pick<
    CmsOurWork,
    'patchtestwarning' | 'patchtestwarningpopup' | 'patchtestpopup'
  >;
  isExpanded?: boolean;
  onCTAClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function PatchTestWarning(props: Props): React.ReactElement {
  const { cms, isExpanded = false, onCTAClick } = props;
  const windowSize = useWindowSize();
  const messageRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (!messageRef.current) {
      return;
    }

    messageRef.current.style.removeProperty('min-height');
    messageRef.current.style.setProperty(
      'min-height',
      `${messageRef.current.clientHeight}px`
    );
  }, [windowSize.width]);

  return (
    <Message
      ref={messageRef}
      className={styles.container}
      cta={
        !isExpanded ? (
          <Text
            as="div"
            className={styles.cta}
            type="captionHeavy"
            onClick={onCTAClick}
          >
            {cms.patchtestpopup}
          </Text>
        ) : undefined
      }
    >
      <div>
        <Text as="div" type={isExpanded ? 'bodyHeavy' : 'body'}>
          {cms.patchtestwarning}
        </Text>
        <Stack
          className={clsx(styles.message, {
            [styles.expanded]: isExpanded,
          })}
          space="lg"
        >
          {splitToParagraphs(cms.patchtestwarningpopup).map(
            (paragraph, index) => (
              <Text key={index} as="div" type="body">
                {paragraph}
              </Text>
            )
          )}
        </Stack>
      </div>
    </Message>
  );
}
