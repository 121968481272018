import {
  CommonSearchInput,
  CommonSearchInputHandle,
} from 'js/components/controls/CommonSearchInput';
import {
  isItemResultType,
  ItemResult,
  ItemResultData,
  ItemResultType,
  makeItemResult,
  MAX_RECENT_ENTRIES_COUNT,
} from 'js/components/controls/search-item-result';
import {
  SectionData,
  SectionDataItem,
} from 'js/components/controls/SelectInput/SectionData';
import { arrayWithoutNullables } from 'js/helpers/array';
import { RequestData } from 'js/helpers/service';
import { CmsCommonControls } from 'js/model/cms/cms-common-controls';
import {
  fetchLocationSearchResultsDebounced,
  ResultValueType,
} from 'js/service/searchService';
import * as React from 'react';
import { LocationIcon } from './LocationIcon';

interface PopularLocation {
  name: string;
  normalisedName: string;
}

interface Props {
  cmsCommonControls: CmsCommonControls;
  pageData: RequestData;
  popularLocations: PopularLocation[];
  isErrorStyling: boolean;
  recentLocations?: {
    entityType: string;
    entityValue: string;
    name: string;
  }[];
  placeholder?: string;
  hasCurrentLocationOption?: boolean;
  onSearchResultsChange: (value: string, results: ItemResult[]) => void;
  onChange: (
    value: string,
    selectedKey: string | null,
    data: ItemResultData | null,
    index: number
  ) => void;
  onComplete?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  isPatternedBackground?: boolean;
}

export interface LocationSearchInputHandle {
  clear: () => void;
  setSelectedItemKey: (selectedItemKey: string) => void;
  currentLocationItem: () => ItemResult & {
    type: ItemResultType.CurrentLocation;
  };
}

export const LocationSearchInput = React.forwardRef(
  (
    { recentLocations = [], hasCurrentLocationOption = false, ...props }: Props,
    ref: React.Ref<LocationSearchInputHandle>
  ) => {
    const commonSearchInputRef = React.useRef<CommonSearchInputHandle>(null);
    const debouncedSearch = fetchLocationSearchResultsDebounced(
      200,
      ResultValueType.NormalisedName
    );

    React.useImperativeHandle(ref, () => ({
      clear(): void {
        if (commonSearchInputRef.current === null) {
          return;
        }
        commonSearchInputRef.current.clear();
      },
      setSelectedItemKey(selectedItemKey: string): void {
        if (commonSearchInputRef.current === null) {
          return;
        }
        commonSearchInputRef.current.setSelectedItemKey(selectedItemKey);
      },
      currentLocationItem,
    }));

    async function locationSearchFunction(
      value: string
    ): Promise<ItemResult[]> {
      const results = await debouncedSearch(props.pageData, value);

      if (props.onSearchResultsChange) {
        props.onSearchResultsChange(value, results);
      }

      return results;
    }

    function dataForNoSearch(): SectionData<ItemResultData>[] {
      return arrayWithoutNullables([
        getRecentLocations(),
        getPopularLocations(),
      ]).filter((section) => section.items.length > 0); // only include sections that have items
    }

    function currentLocationItem(): ItemResult & {
      type: ItemResultType.CurrentLocation;
    } {
      const label =
        props.cmsCommonControls.search['location-current-location-label'];
      const item = makeItemResult(
        '',
        ItemResultType.CurrentLocation,
        '',
        label
      );

      return {
        ...item,
        type: ItemResultType.CurrentLocation,
      };
    }

    function getRecentLocations(): SectionData<ItemResultData> | undefined {
      if (!recentLocations) {
        return;
      }

      const heading = props.cmsCommonControls.search['recent-searches-heading'];

      let items: SectionDataItem<ItemResultData>[];
      try {
        items = recentLocations
          .filter(
            (location) => location.entityType !== ItemResultType.CurrentLocation
          )
          .slice(0, MAX_RECENT_ENTRIES_COUNT)
          .map((location) => {
            if (!isItemResultType(location.entityType)) {
              throw new Error(`unexpected entityType: ${location.entityType}`);
            }

            return makeItemResult(
              'recent',
              location.entityType,
              location.entityValue,
              location.name
            );
          });
      } catch (error) {
        console.error(error);
        return;
      }

      return { heading, items };
    }

    function getPopularLocations(): SectionData<ItemResultData> | undefined {
      if (!props.popularLocations) {
        return;
      }

      const heading =
        props.cmsCommonControls.search['location-popular-heading'];

      const items = props.popularLocations.map((location) =>
        makeItemResult(
          'popular',
          ItemResultType.Location,
          location.normalisedName,
          location.name
        )
      );

      return { heading, items };
    }

    return (
      <CommonSearchInput
        cmsCommonControls={props.cmsCommonControls}
        ref={commonSearchInputRef}
        icon={LocationIcon}
        placeholder={
          props.placeholder ??
          props.cmsCommonControls.search['location-placeholder-local']
        }
        searchResultsHeading={
          props.cmsCommonControls.search['location-results-heading']
        }
        dataForNoSearch={dataForNoSearch()}
        dataAlwaysInDropdown={
          hasCurrentLocationOption
            ? [{ items: [currentLocationItem()] }]
            : undefined
        }
        searchFunction={locationSearchFunction}
        isErrorStyling={props.isErrorStyling}
        isHotJarWhiteList
        onChange={props.onChange}
        onComplete={props.onComplete}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        isPatternedBackground={props.isPatternedBackground}
      />
    );
  }
);

LocationSearchInput.displayName = 'LocationSearchInput';
