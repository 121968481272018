import React from 'react';
import clsx from 'clsx';
import gridStyles from 'assets/style/grid.module.css';

import { fetchVenueReviews } from 'js/service/venueService';
import { scrollWindowTo } from 'js/helpers/animations';
import { getWindowDeviceType } from 'js/helpers/dom';
import { RequestData } from 'js/helpers/service';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { PaginationOutput } from 'js/model/rainbow/PaginationOutput';
import { ReviewOutput } from 'js/model/ReviewOutput';
import { VenuePage } from 'js/model/rainbow/page/VenuePageOutput';
import { usePrevious } from 'js/hooks/usePrevious';
import { PropsType } from 'js/types/react';
import { Stack } from '@treatwell/ui';
import { trackResetFilters } from './reviews-tracking';
import { VerifiedReviewsBlock } from './VerifiedReviewsBlock';
import { VenueReviewsList } from './VenueReviewsList';
import { ReviewFilter } from './ReviewFilter';
import { VenueReviewsEmpty } from './VenueReviewsEmpty';
import { VenueTypeLinks } from './VenueTypeLinks';
import { REVIEWS_PER_PAGE } from './constants';
import styles from './VenueReviews.module.css';

interface Props {
  i18n: (key: string, count?: number | string) => string;
  requestData: RequestData;
  venue: VenuePage;
  ratingHistogram?: { rating: number; count: number }[];
  channelData: Channel;
  pagination?: PaginationOutput;
  generatePaginationUri?: (pageNumber: number) => string;
  venueTypeLinks?: PropsType<typeof VenueTypeLinks>;
}

interface State {
  loading: boolean;
  fetchFailed: boolean;
  page: number;
  nextPage: number;
  ratingHistogram: { rating: number; count: number }[];
  selectedRatings: { [key: string]: boolean };
  selectedOption: number | string;
  reviews: ReviewOutput[];
  pagination?: PaginationOutput;
}

const getSelectedRatingsList = (ratings: {
  [key: string]: boolean;
}): string[] =>
  Object.keys(ratings).reduce(
    (selected: string[], rating: string) =>
      ratings[rating] ? selected.concat(rating) : selected,
    []
  );

const getSelectedTreatmentId = (
  selectedOption: number | string | null
): number | null => (selectedOption === 'ALL' ? null : Number(selectedOption));

const ratingOrder = [5, 4, 3, 2, 1];
const DEFAULT_OPTION = 'ALL';

const getBlankRatings = () => {
  const selectedRatings: { [key: string]: boolean } = {};

  ratingOrder.forEach((rating) => {
    selectedRatings[rating] = false;
  });
  return selectedRatings;
};

export function Reviews(props: Props): React.ReactElement {
  const reviewsNode = React.useRef<HTMLDivElement>(null);

  const [state, setState] = React.useState<State>({
    loading: false,
    fetchFailed: false,
    page: 0,
    nextPage: 1,
    ratingHistogram: props.venue.venueReviews.ratingHistogram,
    selectedRatings: getBlankRatings(),
    selectedOption: DEFAULT_OPTION,
    reviews: props.venue.venueReviews.reviews,
    pagination: props.pagination,
  });

  const hasMore = (
    ratingHistogram: { rating: number; count: number }[]
  ): boolean => {
    const selectedKeys = Object.keys(state.selectedRatings).filter(
      (key) => state.selectedRatings[key]
    );
    const histogram = selectedKeys.length
      ? ratingHistogram.filter(({ rating }) => state.selectedRatings[rating])
      : ratingHistogram;
    const total = histogram.reduce(
      (count: number, rating: { count: number }) => count + rating.count,
      0
    );
    const loaded = (state.page + 1) * REVIEWS_PER_PAGE;

    return total > loaded && state.reviews.length > 0;
  };

  const loadMoreReviews = (): void => {
    loadReviews(true);
  };

  const onPaginationClick = (pageNumber: number) => {
    if (pageNumber === 0 && props.generatePaginationUri !== undefined) {
      window.location.href = props.generatePaginationUri(pageNumber + 1);
      return;
    }

    loadReviews(false, pageNumber);
    const currentReviewsNode = reviewsNode.current;
    if (currentReviewsNode === null) {
      return;
    }
    scrollWindowTo(currentReviewsNode.getBoundingClientRect()?.top - 25);
  };

  const loadReviews = (
    append = false,
    pageNumber: number | null = null
  ): void => {
    let pageTemp: number;

    if (pageNumber === null) {
      pageTemp = append ? state.page + 1 : 0;
    } else {
      pageTemp = pageNumber;
    }

    if (props.generatePaginationUri) {
      window.history.pushState(
        null,
        'null',
        props.generatePaginationUri(pageTemp + 1)
      );
    }
    setState({
      ...state,
      loading: true,
      fetchFailed: false,
    });

    fetchVenueReviews(
      props.requestData,
      props.venue.venue.id,
      getSelectedTreatmentId(state.selectedOption),
      null,
      pageTemp,
      REVIEWS_PER_PAGE,
      getSelectedRatingsList(state.selectedRatings),
      true,
      !!getSelectedTreatmentId(state.selectedOption)
    )
      .then((data) => {
        if (!data) {
          return;
        }

        const newReviews = append
          ? state.reviews.concat(data.reviews)
          : data.reviews;
        setState({
          ...state,
          loading: false,
          reviews: newReviews,
          page: pageTemp,
          nextPage: pageTemp + 1,
          fetchFailed: false,
          ratingHistogram: data.ratingHistogram,
          pagination: data.pagination,
        });
      })
      .catch(() => {
        setState({
          ...state,
          loading: false,
          fetchFailed: true,
        });
      });
  };

  const prevSelectedOptions = usePrevious(state.selectedOption);
  const prevSelectedRatings = usePrevious(state.selectedRatings);

  React.useEffect(() => {
    if (prevSelectedOptions && prevSelectedRatings) {
      loadReviews();
    }
  }, [state.selectedOption, state.selectedRatings]);

  const handleFilterChange = (
    selectedOption: number | string,
    selectedRatings: {}
  ) => {
    setState({ ...state, selectedRatings, selectedOption });
  };

  const handleResetFilters = (): void => {
    handleFilterChange(DEFAULT_OPTION, getBlankRatings());
    trackResetFilters();

    resetScrollPosition();
  };

  const resetScrollPosition = (): void => {
    const isMobileDevice = getWindowDeviceType() === 'mobile';
    const currentReviewsNode = reviewsNode.current;
    if (!isMobileDevice || currentReviewsNode === null) {
      return;
    }

    scrollWindowTo(currentReviewsNode.getBoundingClientRect().top);
  };

  const { i18n, channelData, venue, generatePaginationUri, venueTypeLinks } =
    props;

  const treatments =
    venue.venueReviews.treatmentCategoryOverallRatingAverages || [];

  const reviewsClasses = clsx(
    gridStyles['col-xs-12'],
    gridStyles['col-sm-8'],
    gridStyles['col-lg-8'],
    gridStyles['col-sm-offset-4'],
    gridStyles['col-md-offset-0']
  );
  const venueReviewSidebarClasses = clsx(
    gridStyles['col-md-4'],
    gridStyles['col-md-offset-0'],
    gridStyles['col-sm-8'],
    gridStyles['col-sm-offset-4'],
    styles.venueReviewSidebar
  );
  const ratingCounts = new Array(5);

  state.ratingHistogram.forEach(({ rating, count }) => {
    ratingCounts[rating - 1] = count;
  });

  const reviewsList = state.reviews.length ? (
    <Stack space="md">
      <VenueReviewsList
        i18n={i18n}
        channelData={channelData}
        venueId={venue.venue.id}
        reviews={state.reviews}
        nextPage={state.nextPage}
        more={hasMore(state.ratingHistogram)}
        fetchFailed={state.fetchFailed}
        venueNormalisedName={venue.venue.normalisedName}
        generateReviewsUri
        onLoadMoreReviews={loadMoreReviews}
        loading={state.loading}
        pagination={state.pagination}
        generatePaginationUri={generatePaginationUri}
        onPaginationClick={onPaginationClick}
        showTreatmentNames={treatments.length > 0}
      />
    </Stack>
  ) : (
    <VenueReviewsEmpty i18n={i18n} resetFilters={handleResetFilters} />
  );

  const venueTypeLinksComponent = venueTypeLinks && (
    <VenueTypeLinks {...venueTypeLinks} />
  );

  return (
    <>
      <div className={gridStyles.row} ref={reviewsNode} data-cy="Reviews">
        <div className={venueReviewSidebarClasses}>
          <ReviewFilter
            ratingCounts={ratingCounts.reverse()}
            options={treatments}
            i18n={i18n}
            onChange={handleFilterChange}
            selectedRatings={state.selectedRatings}
            selectedOption={state.selectedOption}
          />
          <VerifiedReviewsBlock
            heading={i18n('venue.reviews.verified-reviews.heading') as string}
            body={i18n('venue.reviews.verified-reviews.body') as string}
          />
          {venueTypeLinks && (
            <div className={styles.venueTypeDesktopWrapper}>
              {venueTypeLinksComponent}
            </div>
          )}
        </div>
        <div className={reviewsClasses}>{reviewsList}</div>
      </div>
      {venueTypeLinks && (
        <div className={styles.venueTypeMobileWrapper}>
          {venueTypeLinksComponent}
        </div>
      )}
    </>
  );
}
