import React from 'react';
import clsx from 'clsx';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';
import { Context } from 'js/components/LocaleWrapper';
import styles from './NearbyVenueType.module.css';

interface Props {
  location: string;
  types: VenueTypeOutput | VenueTypeOutput[];
  isType?: boolean;
}

export function NearbyVenueType(props: Props): React.ReactElement {
  const { location, types, isType = false } = props;

  const typesList = !Array.isArray(types) ? [types] : types;
  const links = typesList.map((type, index) => (
    <Context.Consumer key={index}>
      {(value) => (
        <a
          href={type.venueListingUri!.desktopUri}
          className={clsx(styles.link, {
            [styles['main-link']]: isType,
          })}
        >
          {value.i18n(
            'page.venue.venues-nearby.venue-type',
            type.pluralName,
            location
          )}
        </a>
      )}
    </Context.Consumer>
  ));

  return <div className={styles.wrapper}>{links}</div>;
}
