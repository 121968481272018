import { SelectedServices } from './VisitConfiguratorBar';

export const getDateTimeUri = ({
  venueId,
  selected,
  date,
  timeFrom,
  language,
}: {
  venueId: number;
  selected: SelectedServices;
  date?: string;
  timeFrom?: string;
  language?: string;
}): string => {
  const selectedServices = Object.values(selected);
  const proposedServices = selectedServices.flatMap((item) => {
    return Object.entries(item).map(([serviceId, skus]) => {
      return {
        menuItemId: serviceId,
        optionIds: Object.keys(skus),
      };
    });
  });
  const params = new URLSearchParams();

  params.append('venueId', String(venueId));
  params.append('proposedServices', JSON.stringify(proposedServices));
  if (date) {
    params.append('date', date);
  }
  if (timeFrom) {
    params.append('timeFrom', timeFrom);
  }
  if (language) {
    return `/${language}/availability?${params.toString()}`;
  }
  return `/availability?${params.toString()}`;
};
