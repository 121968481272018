import React from 'react';
import { Inline, Text } from '@treatwell/ui';
import { Chevron, Size, Direction } from 'js/components/Icons/Chevron/Chevron';
import { getWindowDeviceType } from 'js/helpers/dom';
import { RatingSelect } from 'js/components/form/RatingSelect/RatingSelect';
import {
  trackRatingSelect,
  trackTreatmentSelect,
  trackRatingToggle,
} from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/reviews-tracking';
import { TreatmentCategoryOverallRatingAverage } from 'js/model/rainbow/venue/VenueReviews';
import { RatingCheckbox } from './RatingCheckbox';
import styles from './ReviewFilter.module.css';

const ratingOrder = [5, 4, 3, 2, 1];

const getFormattedOptions = (
  options: TreatmentCategoryOverallRatingAverage[]
) =>
  options.map((option) => ({
    value: option.id.toString(),
    label: option.name,
    rating: option.average ? option.average.toFixed(1) : option.average,
  }));

interface Props {
  selectedRatings: { [key: number]: boolean };
  selectedOption: number | string;
  ratingCounts: number[];
  options: { id: number; name: string; average: number }[];
  i18n: (key: string, count?: number | string) => string;
  onChange: (selectedOption: number | string, selectedRatings: {}) => void;
}

interface State {
  ratingCountExpanded: boolean;
  isBrowser: boolean;
}

export class ReviewFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ratingCountExpanded: false,
      isBrowser: false,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('resize', () => this.forceUpdate());
    this.setState({ isBrowser: true });
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.forceUpdate());
  }

  public handleChange = (
    selectedOption: number | string,
    selectedRatings: {}
  ): void => {
    this.props.onChange(selectedOption, selectedRatings);
  };

  public handleToggleRatingCount = () => {
    const ratingCountExpanded = !this.state.ratingCountExpanded;

    this.setState({
      ratingCountExpanded: !this.state.ratingCountExpanded,
    });
    trackRatingToggle(ratingCountExpanded);
  };

  public handleRatingSelect = (rating: number, checked: boolean) => {
    const { selectedOption, selectedRatings } = this.props;
    const ratings = {
      ...selectedRatings,
      [rating]: checked,
    };

    this.handleChange(selectedOption, ratings);
    trackRatingSelect(rating, checked);
  };

  public handleTreatmentSelect = (selectedOption: number | string) => {
    const { selectedRatings } = this.props;

    this.handleChange(selectedOption, selectedRatings);
    trackTreatmentSelect(selectedOption);
  };

  public render(): React.ReactNode {
    const { ratingCounts, options, i18n, selectedRatings, selectedOption } =
      this.props;
    const { ratingCountExpanded } = this.state;
    const total = ratingCounts.reduce((a, b) => a + b, 0);
    const treatmentOptions = options || [];
    const isMobile = this.state.isBrowser && getWindowDeviceType() === 'mobile';
    const showRatingCount = ratingCountExpanded || !isMobile;
    const showRatingSelect = treatmentOptions.length > 0;
    const formattedOptions = getFormattedOptions(treatmentOptions).sort(
      (a, b) => (a.label > b.label ? 1 : -1)
    );
    const displayOptions = [
      {
        value: 'ALL',
        label: i18n('page.venue.review-filter.all-treatments'),
      },
    ].concat(formattedOptions);

    return (
      <div className={styles.reviewFilter}>
        {showRatingSelect ? (
          <Text as="h3" type="caption" className={styles.fullTitle}>
            {i18n('page.venue.review-filter.filter-by-treatment')}
          </Text>
        ) : null}
        <Text as="h3" type="smHeader" className={styles.mobileTitle}>
          {i18n('page.venue.review-filter.filter-reviews')}
        </Text>
        {showRatingSelect ? (
          <div className={styles.treatmentSelect}>
            <Inline align="center">
              <Text className={styles.treatmentSelectTitle}>
                {i18n('page.venue.review-filter.treatment')}
              </Text>
              <RatingSelect
                options={displayOptions}
                onChange={this.handleTreatmentSelect}
                value={selectedOption}
              />
            </Inline>
          </div>
        ) : null}
        <Text as="h3" type="caption" className={styles.subTitle}>
          {i18n('page.venue.review-filter.filter-by-rating')}
        </Text>
        <Inline
          align="center"
          justify="between"
          className={styles.ratingToggle}
          onClick={this.handleToggleRatingCount}
          data-cy="ToggleRating"
        >
          <Text>{i18n('page.venue.review-filter.toggle-counts')}</Text>
          <Text type="smHeader">
            <Inline align="center">
              {i18n('page.venue.review-filter.filter-by-rating')}
              <Chevron
                containerStyle={{ marginLeft: '8px !important' }}
                colour="bloomGreyNavy500"
                size={Size.Medium}
                direction={ratingCountExpanded ? Direction.Up : Direction.Down}
                animated
              />
            </Inline>
          </Text>
        </Inline>
        {showRatingCount ? (
          <div className={styles.ratingCounts}>
            {ratingOrder.map((rating) => (
              <RatingCheckbox
                key={rating}
                checked={selectedRatings[rating]}
                rating={rating.toString()}
                count={ratingCounts[5 - rating]}
                totalReviews={total}
                onChange={(checked: boolean) =>
                  this.handleRatingSelect(rating, checked)
                }
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
