import { VenueOpeningHoursOutput } from 'js/model/rainbow/venue/VenueOpeningHoursOutput';
import { TimeLabel, localizedTime } from 'js/helpers/time';
import { CmsCommon } from 'js/model/cms/cms-common';
import { getWeekdayToday } from 'js/helpers/date';

interface OpeningHoursRange {
  from: TimeLabel;
  to: TimeLabel;
}

function getLocalisedHoursLabel(
  openingHours: VenueOpeningHoursOutput[],
  index: number,
  countryCode: string
): OpeningHoursRange {
  return {
    from: localizedTime(openingHours[index].from!, countryCode),
    to: localizedTime(openingHours[index].to!, countryCode),
  };
}

function getOpeningTimes(
  openLabel: string,
  openingHoursRange: OpeningHoursRange
): string {
  let label = '';
  openingHoursRange.from[1] !== undefined ||
  openingHoursRange.to[1] !== undefined
    ? (label = `${openLabel}: ${openingHoursRange.from[0]} ${openingHoursRange.from[1]} - ${openingHoursRange.to[0]} ${openingHoursRange.to[1]}`)
    : (label = `${openLabel}: ${openingHoursRange.from[0]} - ${openingHoursRange.to[0]}`);
  return label;
}

function relativeOpeningTimesLabel(
  openingHours: VenueOpeningHoursOutput[],
  index: number,
  countryCode: string,
  cms: CmsCommon,
  dayLabel: string
): string {
  const openingHoursRange = getLocalisedHoursLabel(
    openingHours,
    index,
    countryCode
  );
  const label = cms.labels.open.replace('{0}', dayLabel);
  return getOpeningTimes(label, openingHoursRange);
}

export function getVenueRelativeOpeningHours(
  openingHours: VenueOpeningHoursOutput[],
  countryCode: string,
  cms: CmsCommon
): string {
  let relativeOpeningHours = '';

  const dayNames = cms['day-names'];

  const todayDay = getWeekdayToday();

  const todayIndex = openingHours.findIndex(
    (day) => day.dayOfWeek === todayDay
  );
  const isLastDayOfWeek = todayIndex === openingHours.length - 1;
  const tomorrowIndex = isLastDayOfWeek ? 0 : todayIndex + 1;

  const isOpenToday = openingHours[todayIndex].open;
  const isOpenTomorrow = isLastDayOfWeek
    ? openingHours[0].open
    : openingHours[tomorrowIndex].open;

  const currentDateTime = new Date();
  const currentHour = currentDateTime.getHours().toString().padStart(2, '0');
  const currentMinute = currentDateTime
    .getMinutes()
    .toString()
    .padStart(2, '0');
  const currentTimeToday = `${currentHour}:${currentMinute}`;
  const closingTimeToday = openingHours[todayIndex].to!;
  const isOpenAtCurrentTime = closingTimeToday > currentTimeToday;

  let nextOpenDayIndex = 0;

  if (!isLastDayOfWeek) {
    nextOpenDayIndex = openingHours.findIndex(
      (day, index) => day.open && index > tomorrowIndex
    );
    if (nextOpenDayIndex === -1) {
      nextOpenDayIndex = openingHours.findIndex((day) => day.open);
    }
  } else {
    nextOpenDayIndex = openingHours.findIndex((day) => day.open);
  }

  if (isOpenToday && isOpenAtCurrentTime) {
    relativeOpeningHours = relativeOpeningTimesLabel(
      openingHours,
      todayIndex,
      countryCode,
      cms,
      cms.labels.today
    );
  } else if (isOpenTomorrow) {
    relativeOpeningHours = relativeOpeningTimesLabel(
      openingHours,
      tomorrowIndex,
      countryCode,
      cms,
      cms.labels.tomorrow
    );
  } else {
    if (nextOpenDayIndex === -1) {
      relativeOpeningHours = '';
      return relativeOpeningHours;
    }
    const key = openingHours[nextOpenDayIndex].dayOfWeek;
    const dayOfTheWeekLabel = dayNames[key];

    relativeOpeningHours = relativeOpeningTimesLabel(
      openingHours,
      nextOpenDayIndex,
      countryCode,
      cms,
      dayOfTheWeekLabel
    );
  }

  return relativeOpeningHours;
}

export function hasOpeningHours(
  openingHoursRange: VenueOpeningHoursOutput[]
): boolean {
  return openingHoursRange.some((range) => range.open);
}
