import React from 'react';
import clsx from 'clsx';
import { Text } from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';
import { VenueSearch } from 'js/components/VenueSearch';
import { PageSection, SectionType } from 'js/components/PageSection';
import { BrowseMoreVenues } from 'js/components/BrowseMoreVenues';
import { NearbyVenueBlock } from 'js/components/InfoColumnBlocks/NearbyVenueBlock';
import {
  getBreadcrumbs,
  BreadcrumbsSection,
} from 'js/pages/VenuePage/BreadcrumbsSection/BreadcrumbsSection';
import InfoColumnBlocks from 'js/components/InfoColumnBlocks';
import { CmsCommon } from 'js/model/cms/cms-common';
import { CmsVenue } from 'js/model/cms/cms-venue';
import { CmsVenuePage } from 'js/model/cms/cms-venue-page';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import gridStyles from 'assets/style/grid.module.css';
import { VenueReviewsSection } from 'js/pages/VenuePage/VenueReviewsSection';
import { VenueHeader } from './VenueHeader/VenueHeader';
import { VenueAboutSection } from './VenueAboutSection';
import { trackEvent, Category, Property, Action } from './ClosedVenue/tracking';
import styles from './styles.module.css';
import { VenueMenuSection } from './VenueMenuSection/VenueMenuSection';

interface Props {
  isChurned?: boolean;
}

export function ClosedVenue(props: Props): React.ReactElement {
  const { isChurned } = props;
  const { pageData, channel, i18n } = React.useContext(Context);
  const venuePage = pageData.venue;
  const venue = venuePage.venue;
  const proximityVenue = venuePage.proximityVenue;
  let browseMoreVenues;
  const showReviews = Boolean(
    isChurned && venuePage.venueReviews.reviews.length
  );

  if (venue.subTypes && venue.subTypes.length) {
    browseMoreVenues = (
      <BrowseMoreVenues
        onLinkClick={(subVenueTypeId) => {
          trackEvent(
            Category.VenueLocator,
            Property.BrowseMoreVenues,
            Action.Click,
            subVenueTypeId.toString()
          );
        }}
        nearbyVenueSubTypes={venue.subTypes}
      />
    );
  }

  const searchBlock = venue.type ? (
    <VenueSearch
      title={i18n('venue.venue-closed-search.title')}
      typeAhead={{ venueTypeIds: venue.type.id }}
      browseUriValues={{ venueType: venue.type.normalisedName }}
      onSelectedLocationChange={(location, index) => {
        trackEvent(
          isChurned
            ? Category.VenueLocatorOnChurnedVenue
            : Category.VenueLocator,
          Property.LocationField,
          `select_${index}`,
          location
        );
      }}
      onSearchButtonClick={(status) => {
        trackEvent(
          isChurned
            ? Category.VenueLocatorOnChurnedVenue
            : Category.VenueLocator,
          Property.SearchButton,
          Action.Click,
          status
        );
      }}
    >
      {browseMoreVenues}
    </VenueSearch>
  ) : null;

  function renderNotice() {
    if (isChurned) {
      const breadcrumbs = getBreadcrumbs(venuePage);
      const searchUri = breadcrumbs[breadcrumbs.length - 1].uri;

      return (
        <Text as="p" type="lgHeader" className={styles.venueClosed}>
          {i18n('venue.venue-churned-notice.beginning').replace(
            '{0}',
            venue.name
          )}{' '}
          <a href={searchUri}>{i18n('venue.venue-churned-notice.search')}</a>{' '}
          {i18n('venue.venue-churned-notice.end')}
        </Text>
      );
    }
    return (
      <Text as="p" type="lgHeader" className={styles.venueClosed}>
        {i18n('venue.venue-closed')}
      </Text>
    );
  }

  return (
    <div data-venue-id={venue.id}>
      <div className={styles.divider} />
      <div className={styles.wrapper}>
        <VenueHeader
          venue={venue}
          isActiveVenue={false}
          channelData={channel}
          cmsCommon={i18n('common') as unknown as CmsCommon}
          cmsVenue={i18n('venue') as unknown as CmsVenue}
          cmsHeaderNavigation={
            i18n(
              'page.venue.header-navigation'
            ) as unknown as CmsVenuePage['page']['venue']['header-navigation']
          }
          cmsChainTemplate={i18n('page.venue.details.chain')}
          hasReviews={pageData.venue.hasReviews}
        />

        <PageSection id="venueClosed" type={SectionType.VeryShort}>
          {renderNotice()}
        </PageSection>

        {!isChurned && proximityVenue?.venues?.length ? (
          <PageSection id="venueClosedNearby" type={SectionType.VeryShort}>
            <Text as="p" type="lgHeader" className={styles.venueClosedNearby}>
              {i18n('venue.venue-closed-nearby')}
            </Text>
          </PageSection>
        ) : null}

        <PageSection id="nearbyVenues">
          <div className={clsx(gridStyles.row, styles.nearbyVenues)}>
            <InfoColumnBlocks
              blocks={proximityVenue?.venues || []}
              customBlock={searchBlock}
              contentBlock={NearbyVenueBlock}
              onClick={async (id: number, key: string) => {
                await trackEvent(
                  isChurned ? Category.ChurnedVenue : Category.OfflineVenue,
                  Property.GoToVenue,
                  `click_${key}`,
                  'recommended_venue',
                  id
                );
              }}
            />
          </div>
        </PageSection>

        {isChurned && <VenueMenuSection />}

        {showReviews && <VenueReviewsSection />}

        {isChurned && (
          <VenueAboutSection
            venue={pageData.venue.venue}
            openingHoursClosedLabel={i18n('venue.opening-hours.closed')}
            dayNames={
              i18n(
                'common.day-names'
              ) as unknown as CmsBrowsePage['common']['day-names']
            }
            dayNamesShort={
              i18n(
                'common.calendar.day-names-short'
              ) as unknown as CmsBrowsePage['common']['calendar']['day-names-short']
            }
            countryCode={pageData.channel.country.countryCode}
            channelCode={pageData.channel.code}
            titleAbout={i18n('page.venue.section-titles.about')}
          />
        )}

        <BreadcrumbsSection />
      </div>
    </div>
  );
}
