import React from 'react';
import clsx from 'clsx';
import { Inline, Text } from '@treatwell/ui';
import styles from './VenueReviewTreatments.module.css';

interface Props {
  treatments: string[];
  showAllLabel: string;
}

export function VenueReviewTreatments(props: Props): React.ReactElement {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const toggleExpansion = (): void => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const getBubble = (
    treatment: string,
    treatmentIndex: number
  ): React.ReactNode => {
    const { showAllLabel, treatments } = props;

    if (!isExpanded && treatmentIndex === 0 && treatments.length > 1) {
      return (
        <Inline space="xxs" align="center" key={treatmentIndex}>
          <Text type="caption" className={styles.bubble}>
            {treatment}
          </Text>
          <Text
            type="caption"
            className={clsx(styles.bubble, styles.showAll)}
            onClick={toggleExpansion}
          >
            {showAllLabel}
          </Text>
        </Inline>
      );
    }
    if (!isExpanded && treatmentIndex !== 0 && treatments.length > 1) {
      return;
    }

    return (
      <Text type="caption" className={styles.bubble} key={treatmentIndex}>
        {treatment}
      </Text>
    );
  };

  const { treatments } = props;

  return (
    <>
      {treatments.map((treatment, treatmentIndex) =>
        getBubble(treatment, treatmentIndex)
      )}
    </>
  );
}
