import React from 'react';

interface Props {
  color: string;
}

export const IconAll: React.FunctionComponent<Props> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Size=24">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 1.5C3.05228 1.5 3.5 1.94772 3.5 2.5C3.5 3.05228 3.05228 3.5 2.5 3.5C1.94772 3.5 1.5 3.05228 1.5 2.5C1.5 1.94772 1.94772 1.5 2.5 1.5ZM2.5 5C3.05228 5 3.5 5.44772 3.5 6C3.5 6.55228 3.05228 7 2.5 7C1.94772 7 1.5 6.55228 1.5 6C1.5 5.44772 1.94772 5 2.5 5ZM2.5 8.5C3.05228 8.5 3.5 8.94772 3.5 9.5C3.5 10.0523 3.05228 10.5 2.5 10.5C1.94772 10.5 1.5 10.0523 1.5 9.5C1.5 8.94772 1.94772 8.5 2.5 8.5ZM6 1.5C6.55228 1.5 7 1.94772 7 2.5C7 3.05228 6.55228 3.5 6 3.5C5.44772 3.5 5 3.05228 5 2.5C5 1.94772 5.44772 1.5 6 1.5ZM6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5ZM6 8.5C6.55228 8.5 7 8.94772 7 9.5C7 10.0523 6.55228 10.5 6 10.5C5.44772 10.5 5 10.0523 5 9.5C5 8.94772 5.44772 8.5 6 8.5ZM9.5 1.5C10.0523 1.5 10.5 1.94772 10.5 2.5C10.5 3.05228 10.0523 3.5 9.5 3.5C8.94772 3.5 8.5 3.05228 8.5 2.5C8.5 1.94772 8.94772 1.5 9.5 1.5ZM9.5 5C10.0523 5 10.5 5.44772 10.5 6C10.5 6.55228 10.0523 7 9.5 7C8.94772 7 8.5 6.55228 8.5 6C8.5 5.44772 8.94772 5 9.5 5ZM9.5 8.5C10.0523 8.5 10.5 8.94772 10.5 9.5C10.5 10.0523 10.0523 10.5 9.5 10.5C8.94772 10.5 8.5 10.0523 8.5 9.5C8.5 8.94772 8.94772 8.5 9.5 8.5Z"
        fill={props.color}
      />
    </g>
  </svg>
);
