import { Dictionary } from 'js/types/generic-types';
import * as React from 'react';
import {
  KEY_CODE_DOWN,
  KEY_CODE_UP,
  KEY_CODE_PAGE_DOWN,
  KEY_CODE_PAGE_UP,
  KEY_CODE_ENTER,
} from 'js/constants/keycodes';
import { SectionData } from './SectionData';
import styles from './SelectInput.module.css';
import { List, findLabelForKey, ListHandle } from './List';
import { DropdownInput } from '../DropdownInput';
import {
  ClearableMode,
  DropdownInputHandle,
} from '../DropdownInput/DropdownInput';
import { ItemResultType } from '../search-item-result';

export interface Props {
  // A React component returning an SVG that will be shown to the left of the input.
  // The image be up to 24px x 24px.
  icon: React.ComponentType<{ positioningClassName?: string; error?: boolean }>;

  isClearable?: ClearableMode;

  // Whether the field should be focused and the dropdown opened when the user clicks the clear icon on
  // the right side of the input. Can be disabled on non mandatory fields.
  isFocusOnClear?: boolean;

  // Whether the user is allowed to type in the input field. Default: true
  isTextEditable?: boolean;

  // Show styling for validation failure
  // if true, red error styles are applied to the InputField & icon
  isErrorStyling?: boolean;

  isHotJarWhiteList?: boolean;

  placeholder: string;

  // An item's key that will be seen as no selection. When the placeholder item is selected the field
  // will be cleared, showing it with the light grey placeholder text. The placeholder text will be the
  // item's label. The change event will not be dispatched with the key/value of the placeholder item, but
  // instead like there was no selection.
  placeholderItemKey?: string;

  // The text to show to close the mobile viewport popover.
  // If not provided, the text will be taken from a cms item value.
  closeButtonText: string;

  // data to show in the list. can be null or undefined. in this case no dropdown list/full screen input is shown.
  sectionData: SectionData[];

  renderHiddenResults?: boolean;

  // called when the text fields value changed
  // value: the value that was typed into the field or the items label
  // selectedKey: null or the selected item key when change occurred due to
  //   selection or setter (cursor navigation) of a list item
  onChange?: (value: string, selectedKey: string | null) => void;

  // called when the user clicks on one list item or presses the enter key
  // the owner should make sure to pass focus to next field
  onComplete?: () => void;

  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  isPatternedBackground?: boolean;
}

export interface SelectInputHandle {
  setSelectedItemKey: (selectedItemKey: string | null) => void;
}

export const SelectInput = React.forwardRef(
  (
    { renderHiddenResults = false, ...props }: Props,
    ref: React.Ref<SelectInputHandle>
  ) => {
    const NAVIGATION_KEYCODE_OFFSETS: Dictionary<number> = {
      [KEY_CODE_UP]: -1,
      [KEY_CODE_DOWN]: 1,
      [KEY_CODE_PAGE_UP]: -5,
      [KEY_CODE_PAGE_DOWN]: 5,
    };

    const listRef = React.useRef<ListHandle>(null);
    const dropdownInputRef = React.useRef<DropdownInputHandle>(null);
    const [selectedItemKey, setSelectedItemKey] = React.useState<string | null>(
      props.placeholderItemKey ?? null
    );
    const [placeholder, setPlaceholder] = React.useState<string>(
      props.placeholder ?? ''
    );

    // Component state is set asynchronously, and may be in progress when
    // a blur event is handled. So this.state.selectedItemKey cannot be
    // relied on in the blur handler.
    //
    // Maintaining selectedItemKey in a private field worksaround this.
    const _selectedItemKey = React.useRef<string | null>(null);

    React.useEffect(() => {
      let placeholder = props.placeholder;
      if (props.placeholderItemKey && props.sectionData) {
        const label = findLabelForKey(
          props.sectionData,
          props.placeholderItemKey
        );
        if (label !== null) {
          placeholder = label;
        }
      }
      setPlaceholder(placeholder);
    }, [props.placeholderItemKey, props.sectionData, props.placeholder]);

    React.useImperativeHandle(ref, () => ({
      /**
       * Public method to set the the selected item. This will update the input field text to reflect the
       * selection. It also highlights the corresponding list item.
       *
       * @param selectedItemKey - The item's key
       */
      setSelectedItemKey(selectedItemKey: string | null): void {
        if (!props.sectionData || !selectedItemKey) {
          internalSetSelectedItemState(null, '');
          return;
        }

        const label = findLabelForKey(props.sectionData, selectedItemKey);

        if (label === null) {
          internalSetSelectedItemState(null, '');
          return;
        }

        internalSetSelectedItemState(selectedItemKey, label);
      },
    }));

    function internalSetSelectedItemState(
      key: string | null,
      value: string
    ): void {
      let displayValue = value;
      let dispatchKey = key;

      if (key === props.placeholderItemKey) {
        displayValue = '';
        dispatchKey = null;
      }

      if (dropdownInputRef.current !== null) {
        dropdownInputRef.current.setValue(displayValue);
      }
      setSelectedItemKey(key);
      _selectedItemKey.current = key;

      dispatchChange(displayValue, dispatchKey);
    }

    function onDropdownInputChange(value: string, isUserChange: boolean): void {
      if (!isUserChange) {
        return;
      }

      const selectedItemKey = props.placeholderItemKey
        ? props.placeholderItemKey
        : null;
      setSelectedItemKey(selectedItemKey);
      _selectedItemKey.current = selectedItemKey;

      dispatchChange(value, null);
    }

    function closeDropdown(): void {
      if (dropdownInputRef.current) {
        dropdownInputRef.current.blur();
      }
    }

    function onListSelect(key: string, isClickSelection: boolean): void {
      const value = findLabelForKey(props.sectionData, key);
      if (value === null) {
        throw new Error('invalid key');
      }

      internalSetSelectedItemState(key, value);
      dispatchComplete();

      if (isClickSelection && dropdownInputRef.current !== null) {
        dropdownInputRef.current.blur();
      }
    }

    function onBlur(event: React.FocusEvent): void {
      // if,
      //    the input is blurred
      //    AND nothing has been selected
      //    AND there is at least one search result present
      // then automatically select the first search result item
      if (!_selectedItemKey.current) {
        const [itemKey, itemLabel] = firstSearchItem();

        if (itemKey && itemLabel) {
          internalSetSelectedItemState(itemKey, itemLabel);
        }
      }

      props.onBlur?.(event);
    }

    function firstSearchItem(): [string?, string?] {
      for (const section of props.sectionData) {
        for (const item of section.items) {
          if (
            // only interested in items with keys
            item.type !== ItemResultType.TreatmentType &&
            item.type !== ItemResultType.Treatments &&
            item.type !== ItemResultType.Venue &&
            item.type !== ItemResultType.ExternalLocation &&
            item.type !== ItemResultType.Location &&
            item.type !== ItemResultType.PostalArea &&
            item.type !== ItemResultType.PostalReference &&
            item.type !== ItemResultType.VenueType
          ) {
            // eslint-disable-next-line no-continue
            continue;
          }

          if (item.key.startsWith('search')) {
            return [item.key, item.label];
          }
        }
      }

      return [];
    }

    function onKeyDown(keyCode: number): boolean {
      if (keyCode === KEY_CODE_ENTER) {
        if (dropdownInputRef.current) {
          dropdownInputRef.current.blur();
        }

        dispatchComplete();
        return false;
      }

      const navigationOffset: number | undefined =
        NAVIGATION_KEYCODE_OFFSETS[keyCode];

      if (navigationOffset === undefined) {
        return false;
      }

      if (listRef.current) {
        listRef.current.moveSelectedItemByOffset(navigationOffset);
      }

      return true;
    }

    function dispatchChange(value: string, key: string | null): void {
      if (props.onChange) {
        props.onChange(value, key);
      }
    }

    function dispatchComplete(): void {
      if (props.onComplete) {
        props.onComplete();
      }
    }

    function renderList(): React.ReactElement | null {
      if (
        !(props.sectionData instanceof Array) ||
        props.sectionData.length <= 0
      ) {
        return null;
      }

      return (
        <List
          ref={listRef}
          positioningClassName={styles.list}
          selectedItemKey={
            selectedItemKey !== null
              ? selectedItemKey
              : undefined /* TODO converge bottom type */
          }
          sectionData={props.sectionData}
          onSelect={onListSelect}
          closeDropdown={closeDropdown}
        />
      );
    }

    return (
      <DropdownInput
        ref={dropdownInputRef}
        icon={props.icon}
        isClearable={props.isClearable}
        isFocusOnClear={props.isFocusOnClear}
        isTextEditable={props.isTextEditable}
        isErrorStyling={props.isErrorStyling}
        isHotJarWhiteList={props.isHotJarWhiteList}
        placeholder={placeholder}
        closeButtonText={props.closeButtonText}
        onKeyDown={onKeyDown}
        onChange={onDropdownInputChange}
        onFocus={props.onFocus}
        onBlur={onBlur}
        renderHiddenResults={renderHiddenResults}
        isPatternedBackground={props.isPatternedBackground}
      >
        {renderList()}
      </DropdownInput>
    );
  }
);

SelectInput.displayName = 'SelectInput';
