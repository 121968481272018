import React from 'react';
import clsx from 'clsx';
import { Chevron, Size, Direction } from 'js/components/Icons/Chevron/Chevron';
import { getWindowDeviceType } from 'js/helpers/dom';
import range from 'lodash.range';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import styles from './Carousel.module.css';
import { LazyCarouselImage } from './LazyCarouselImage';

const ANIMATION_DURATION = '.4s';

// A swipe across 30% at any speed
const SLOW_PERCENT = 30;

// A swipe across 4% lasting less than 100ms
const FAST_PERCENT = 4;
const FAST_DURATION = 100;

const Pagination = ({
  selectedIndex,
  totalPages,
}: PaginationProps): React.ReactElement => (
  <div className={styles.pagination}>
    {range(totalPages).map((index) => {
      const className =
        selectedIndex === index
          ? clsx(styles.paginationIcon, styles.selectedIcon)
          : styles.paginationIcon;

      return <div key={index} className={className} />;
    })}
  </div>
);

interface PaginationProps {
  selectedIndex: number;
  totalPages: number;
}

function ensureEnoughImages(images: ImageOutput[]): ImageOutput[] {
  const imagesNeeded = 4;

  if (images.length >= imagesNeeded || images.length < 1) {
    return images;
  }

  const newImages = [];
  for (let i = 0; i < imagesNeeded; i++) {
    const loopingIndex = i % images.length;
    newImages[i] = images[loopingIndex];
  }

  return newImages;
}

interface Props {
  images: ImageOutput[];
  altAttr?: string;
  className?: string;
}

interface State {
  keyedImages: ImageOutput[];
  imageIndex: number;
  lastIndex: number;
  didMount: boolean;
}

/*
 * There is a newer, more generic implementation of a carousel with render props at GenericCarousel.
 * This component might be replaced by it.
 */
export class Carousel extends React.PureComponent<Props, State> {
  private animating = false;

  private swipeStartAt = 0;

  private swipeStart = 0;

  private isScrolling = false;

  private swipeYStart = 0;

  private swipeEnd = 0;

  private imageWidth = 0;

  private wrapper: React.RefObject<HTMLDivElement | null> = React.createRef();

  private keyedImages = ensureEnoughImages(this.props.images).map(
    (image, index) => ({
      ...image,
      key: index,
    })
  );

  public state: State = {
    keyedImages: this.keyedImages,
    imageIndex: 0,
    lastIndex: this.keyedImages.length - 1,
    didMount: false,
  };

  public componentDidMount(): void {
    if (!this.wrapper.current) {
      return;
    }
    if (this.state.keyedImages.length > 1) {
      this.imageWidth =
        this.wrapper.current.offsetWidth /
        this.wrapper.current.childNodes.length;
      this.wrapper.current.addEventListener(
        'touchstart',
        this.handleSwipeStart,
        false
      );
      this.wrapper.current.addEventListener(
        'touchmove',
        this.handleSwipeMove,
        false
      );
      this.wrapper.current.addEventListener(
        'touchend',
        this.handleSwipeEnd,
        false
      );
    }
    window.addEventListener('resize', this.repositionImagesWrapper, false);

    this.setState({
      didMount: true,
    });
  }

  public componentWillUnmount(): void {
    if (!this.wrapper.current) {
      return;
    }
    this.wrapper.current.removeEventListener(
      'touchstart',
      this.handleSwipeStart,
      false
    );
    this.wrapper.current.removeEventListener(
      'touchmove',
      this.handleSwipeMove,
      false
    );
    this.wrapper.current.removeEventListener(
      'touchend',
      this.handleSwipeEnd,
      false
    );
    window.removeEventListener('resize', this.repositionImagesWrapper, false);
  }

  private shouldShowImage(imageIndex: number): boolean {
    // Only include first image in server html, load the rest after mounted
    if (!this.state.didMount) {
      // In both mobile and desktop, index 1 is always the first visible image
      // See getImages for more details
      return imageIndex === 1;
    }

    return true;
  }

  private generateImageNode(images: ImageOutput[]): React.ReactNode {
    return images.map((image, index) => {
      if (!this.shouldShowImage(index)) {
        return <div key={image.id} className={styles.image} />;
      }

      return (
        <LazyCarouselImage
          className={styles.image}
          imageData={image.uris}
          sizes="VENUE_PAGE"
          alt={this.props.altAttr}
          key={image.id}
          isLazy={index > 1}
        />
      );
    });
  }

  private getImages(): React.ReactNode {
    const { imageIndex, lastIndex, keyedImages } = this.state;

    if (!keyedImages.length) {
      return null;
    }

    const nextIndex = imageIndex === lastIndex ? 0 : imageIndex + 1;

    /*
     * This is the display buffer for the carousel images.
     * The first venue image is placed at index 1, the last image at index 0
     * This is to enable transitions both to the left and right
     */

    const imagesList = [
      keyedImages[imageIndex === 0 ? lastIndex : imageIndex - 1],
      keyedImages[imageIndex],
      keyedImages[nextIndex],
      keyedImages[nextIndex === lastIndex ? 0 : nextIndex + 1],
    ];

    return this.generateImageNode(imagesList);
  }

  private getNewIndex(action: number): number {
    const newIndex = this.state.imageIndex - action;

    if (newIndex > this.state.lastIndex) {
      return 0;
    }

    if (newIndex < 0) {
      return this.state.lastIndex;
    }

    return newIndex;
  }

  private repositionImagesWrapper = (): void => {
    if (!this.wrapper.current) {
      return;
    }
    const leftPositionRatio = getWindowDeviceType() === 'mobile' ? 1 : 1.8;

    this.wrapper.current.style.left = `calc(100% / ${leftPositionRatio} * -1)`;
    this.imageWidth =
      this.wrapper.current.offsetWidth / this.wrapper.current.childNodes.length;
  };

  private handleSwipe(swipeDirection: number): void {
    if (this.animating || !this.wrapper.current) {
      return;
    }
    const xPos = this.imageWidth * swipeDirection;

    this.animating = true;
    this.wrapper.current.style.transition = `transform ${ANIMATION_DURATION}`;
    this.wrapper.current.style.transform = `translate3d(${xPos}px, 0, 0)`;

    setTimeout(() => {
      if (!this.wrapper.current) {
        return;
      }
      this.animating = false;
      this.wrapper.current.style.transition = 'initial';
      this.wrapper.current.style.transform = 'translate3d(0, 0, 0)';
      this.setState({
        imageIndex: this.getNewIndex(swipeDirection),
      });
    }, 300);
  }

  private handleSwipeStart = (evt: TouchEvent): void => {
    if (!this.wrapper.current) {
      return;
    }
    this.swipeStart = evt.touches[0].clientX;
    this.swipeYStart = evt.touches[0].clientY;
    this.swipeEnd = evt.touches[0].clientX;
    this.wrapper.current.style.transition = 'initial';
    this.swipeStartAt = evt.timeStamp;
  };

  private handleSwipeMove = (evt: TouchEvent): void => {
    if (!this.wrapper.current) {
      return;
    }
    const clientX = evt.touches[0].clientX;
    const clientY = evt.touches[0].clientY;
    const xPos = clientX - this.swipeStart;
    const yPos = clientY - this.swipeYStart;

    // Based on SlickSlider
    // If y-scroll is more than half the image height, assume vertical scroll
    // Else If x-scroll has moved a few pixels, lock vertical scroll
    if (
      this.isScrolling ||
      Math.abs(yPos) > this.wrapper.current.offsetHeight / 2
    ) {
      this.isScrolling = true;
    } else {
      if (Math.abs(xPos) > 4) {
        evt.preventDefault();
      }

      this.swipeEnd = clientX;
      this.wrapper.current.style.transform = `translate3d(${xPos}px, 0, 0)`;
    }
  };

  private handleSwipeEnd = (evt: TouchEvent): boolean => {
    if (!this.wrapper.current) {
      return false;
    }
    const swipeDirection = this.swipeEnd < this.swipeStart ? -1 : 1;

    this.isScrolling = false;

    if (this.shouldSwipe(evt.timeStamp)) {
      this.handleSwipe(swipeDirection);
    } else {
      this.wrapper.current.style.transition = `transform ${ANIMATION_DURATION}`;
      this.wrapper.current.style.transform = 'translate3d(0, 0, 0)';
    }
    return true;
  };

  private shouldSwipe(timeStamp: number): boolean {
    const swipeDistance = Math.abs(this.swipeStart - this.swipeEnd);
    const swipePercent = 100.0 / (this.imageWidth / swipeDistance);
    const swipeDuration = timeStamp - this.swipeStartAt;

    return (
      swipePercent > SLOW_PERCENT ||
      (swipePercent > FAST_PERCENT && swipeDuration < FAST_DURATION)
    );
  }

  public render(): React.ReactNode {
    const { images } = this.props;
    const { keyedImages } = this.state;

    const imageNodes = this.getImages();
    const navigation = keyedImages.length ? (
      <>
        <div className={styles.nav} onClick={() => this.handleSwipe(1)}>
          <Chevron
            containerStyle={{ marginLeft: -2 }}
            colour="white"
            size={Size.Medium}
            direction={Direction.Left}
          />
        </div>
        <div
          className={clsx(styles.nav, styles.right)}
          onClick={() => this.handleSwipe(-1)}
        >
          <Chevron
            containerStyle={{ marginRight: -2 }}
            colour="white"
            size={Size.Medium}
            direction={Direction.Right}
          />
        </div>
      </>
    ) : null;

    const pagination =
      keyedImages.length > 1 ? (
        <Pagination
          selectedIndex={this.state.imageIndex}
          totalPages={images.length}
        />
      ) : null;

    return (
      <div className={clsx(styles.carousel, this.props.className)}>
        <div ref={this.wrapper} className={styles.wrapper}>
          {imageNodes}
        </div>
        {navigation}
        {pagination}
      </div>
    );
  }
}
