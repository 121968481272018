import React from 'react';

interface Props {
  color: string;
}

export const IconHair: React.FunctionComponent<Props> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 16C12.6569 16 14 17.3431 14 19C14 20.6568 12.6569 22 11 22C9.34315 22 8 20.6568 8 19C8 17.3431 9.34315 16 11 16ZM11 18C10.4477 18 10 18.4477 10 19C10 19.5523 10.4477 20 11 20C11.5523 20 12 19.5523 12 19C12 18.4477 11.5523 18 11 18ZM15.1056 2.55278L16.8944 3.44721L13.236 10.7628L20.5528 7.10557L21.4472 8.89442L11.2802 13.9764L10.7628 14.2205C10.2402 14.4616 9.68561 14.7028 9.12139 14.9285C8.65277 15.1159 8.19718 15.2847 7.76067 15.4302C6.77635 15.7583 5.9297 15.9573 5.22838 15.9939L5 16C3.34315 16 2 14.6568 2 13C2 11.3431 3.34315 9.99999 5 9.99999C6.65685 9.99999 8 11.3431 8 13C8 13.0752 7.99724 13.1497 7.99181 13.2234L8.37861 13.0715C8.73163 12.9303 9.08198 12.7821 9.4224 12.6315L10.0463 12.3481L10.2595 12.2479L15.1056 2.55278ZM5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14C5.55228 14 6 13.5523 6 13C6 12.4477 5.55228 12 5 12Z"
      fill={props.color}
    />
  </svg>
);
