import React from 'react';
import { Collapsable } from 'js/components/Collapsable/Collapsable';
import { PageSection } from 'js/components/PageSection';
import { TeamListItemContent } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemContent';
import {
  ClickArea,
  TeamListItemHeading,
} from 'js/pages/VenuePage/VenueTeamSection/TeamListItemHeading/TeamListItemHeading';
import { getWindowDeviceType } from 'js/helpers/dom';
import { IndexDictionary } from 'js/types/generic-types';
import variables from 'src/assets/style/variables.module.css';
import { Viewport, useViewport } from '@treatwell/ui';
import { InView } from 'react-intersection-observer';
import {
  trackFirstVisibleEmployeeViewed,
  trackHeadingClick,
  trackSectionViewed,
} from './tracking';
import styles from './VenueTeamSection.module.css';
import { Sections } from './TeamListItemContent/TeamListItemContent';

export interface TeamMember {
  id: number;
  image: string;
  initials: string;
  name: string;
  rating: number;
  reviewCount: string;
  displayRating?: string;
  title: string;
  sections: Sections;
}

interface Props {
  heading: string;
  teamMembers: TeamMember[];
}

interface State {
  activeTeamMembers: Readonly<IndexDictionary<boolean>>;
}

export function VenueTeamSection(props: Props): React.ReactElement {
  const isDesktop = useViewport({ device: 'desktop' });
  const mediaQueryDesktopList = React.useRef<MediaQueryList | undefined>(
    undefined
  );

  const createInitialActiveTeamMembersState =
    (): State['activeTeamMembers'] => {
      if (props.teamMembers.length === 1) {
        return { [props.teamMembers[0].id]: true };
      }
      return {};
    };

  const [activeTeamMembers, setActiveTeamMembers] = React.useState<
    Readonly<IndexDictionary<boolean>>
  >(createInitialActiveTeamMembersState());

  React.useEffect(() => {
    mediaQueryDesktopList.current = window.matchMedia(
      variables['breakpoint-desktop']
    );
    updateActiveTeamMembersStateForDesktop();
    mediaQueryDesktopList?.current?.addEventListener?.(
      'change',
      updateActiveTeamMembersStateForDesktop
    );

    return () => {
      if (mediaQueryDesktopList !== undefined) {
        mediaQueryDesktopList.current?.removeEventListener(
          'change',
          updateActiveTeamMembersStateForDesktop
        );
      }
    };
  }, []);

  const updateActiveTeamMembersStateForDesktop = (): void => {
    if (getWindowDeviceType() !== 'desktop') {
      return;
    }

    let activeTeamMember = getDesktopActiveTeamMember();
    if (activeTeamMember === undefined && props.teamMembers.length > 0) {
      activeTeamMember = props.teamMembers[0];
    }

    if (activeTeamMember === undefined) {
      setActiveTeamMembers({});
    } else {
      setActiveTeamMembers({ [activeTeamMember.id]: true });
    }
  };

  const trackHasBeenViewed = (inView: boolean) => {
    const { teamMembers } = props;

    if (inView) {
      trackSectionViewed(teamMembers.length);

      if (getWindowDeviceType() === 'desktop' || teamMembers.length === 1) {
        trackFirstVisibleEmployeeViewed(teamMembers[0], teamMembers[0].id);
      }
    }
  };

  const onHeadingClick = (
    teamMember: TeamMember,
    teamMemberIndex: number,
    clickArea: ClickArea,
    isActive: boolean
  ): void => {
    let activeTeamMembersTemp: State['activeTeamMembers'];
    if (getWindowDeviceType() === 'desktop') {
      activeTeamMembersTemp = {
        [teamMember.id]: true,
      };
    } else {
      // mobile & tablet
      activeTeamMembersTemp = {
        ...activeTeamMembers,
        [teamMember.id]: !activeTeamMembers[teamMember.id],
      };
    }
    setActiveTeamMembers(activeTeamMembersTemp);

    trackHeadingClick(teamMemberIndex, teamMember, clickArea, isActive);
  };

  const findTeamMemberById = (teamMemberId: number): TeamMember | undefined => {
    return props.teamMembers.find(
      (teamMember) => teamMember.id === teamMemberId
    );
  };

  const getDesktopActiveTeamMember = (): TeamMember | undefined => {
    const members = Array.from(Object.keys(activeTeamMembers));
    for (const teamMemberId of members) {
      const isActive = activeTeamMembers[+teamMemberId];

      if (isActive) {
        return findTeamMemberById(Number(teamMemberId)); // TODO rethink use of IndexDictionary as iterators infer key of string
      }
    }

    return undefined;
  };

  const renderDesktopActiveTeamMember = (): React.ReactNode => {
    const desktopActiveTeamMember = getDesktopActiveTeamMember();

    return (
      <>
        {desktopActiveTeamMember && (
          <TeamListItemContent {...desktopActiveTeamMember} />
        )}
      </>
    );
  };

  return (
    <>
      {Boolean(props.teamMembers.length) && (
        <InView triggerOnce rootMargin="0px" onChange={trackHasBeenViewed}>
          <PageSection id="team" title={props.heading}>
            <div className={styles.columns}>
              <div
                className={styles.list}
                role="tablist"
                aria-orientation="vertical"
                aria-multiselectable={!isDesktop}
              >
                {props.teamMembers.map((teamMember, teamMemberIndex) => {
                  const isMobileActive =
                    activeTeamMembers[teamMember.id] === true;
                  return (
                    <div
                      className={styles.listItem}
                      key={teamMember.id}
                      id={`team-member-${teamMember.id}`}
                    >
                      <TeamListItemHeading
                        {...teamMember}
                        isActive={activeTeamMembers[teamMember.id] === true}
                        onClick={(clickArea, isActive) =>
                          onHeadingClick(
                            teamMember,
                            teamMemberIndex,
                            clickArea,
                            isActive
                          )
                        }
                      />
                      <Viewport serverRender device={['mobile', 'tablet']}>
                        <Collapsable isVisible={isMobileActive}>
                          <TeamListItemContent {...teamMember} />
                        </Collapsable>
                      </Viewport>
                    </div>
                  );
                })}
              </div>
              <Viewport device="desktop">
                <div className={styles.content}>
                  {renderDesktopActiveTeamMember()}
                </div>
              </Viewport>
            </div>
          </PageSection>
        </InView>
      )}
    </>
  );
}
