import React from 'react';
import { Stack, Inline, useViewport } from '@treatwell/ui';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import { Context } from 'js/components/LocaleWrapper';
import { LanguageSelector } from 'js/components/LanguageSelector';
import { VenueTags } from '../VenueTags';
import { VenueDetails } from '../VenueHeader/VenueDetails';
import styles from './VenueHeader.module.css';
import { Logo } from './Logo/Logo';

type Props = {
  venue: VenueOutput;
  channelData: Channel;
  isActiveVenue?: boolean;
  alternateLocaleUris: { locale: string; uri: string }[];
};

export function VenueHeader(props: Props): React.ReactElement {
  const {
    venue,
    channelData,
    isActiveVenue = true,
    alternateLocaleUris,
  } = props;
  const isMobile = useViewport({ device: 'mobile' });

  const discountTypes = venue.priceRange
    ? venue.priceRange.yieldDiscountTypes
    : [];

  function renderVenueDetails(): React.ReactNode {
    return (
      <>
        <VenueDetails
          venue={venue}
          channelData={channelData}
          isActiveVenue={isActiveVenue}
        />
        <div className={styles.logo}>
          <LanguageSelector
            countryCode={channelData.country.countryCode}
            languageCode={channelData.languageCode}
            availableLanguages={channelData.availableLanguages}
            alternateLocaleUris={alternateLocaleUris}
          />
          <Logo />
        </div>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <Stack space="xs">
        {isMobile ? (
          <Inline justify="between">
            <Stack>{renderVenueDetails()}</Stack>
            <LanguageSelector
              countryCode={channelData.country.countryCode}
              languageCode={channelData.languageCode}
              availableLanguages={channelData.availableLanguages}
              alternateLocaleUris={alternateLocaleUris}
            />
          </Inline>
        ) : (
          <Inline justify="between" align="start">
            {renderVenueDetails()}
          </Inline>
        )}
        <Context.Consumer>
          {(value) => (
            <VenueTags
              discountTypes={discountTypes}
              venueType={venue.type!}
              subTypes={venue.subTypes}
              cms={(value.pageData.cms as CmsBrowsePage).venue}
            />
          )}
        </Context.Consumer>
      </Stack>
    </div>
  );
}
