import * as React from 'react';
import { LocationRadius } from 'js/pages/BrowsePage/WhatWhereWhen/LocationSearchInput/LocationRadius';
import listItemStyles from 'js/components/controls/SelectInput/ListItem.module.css';
import { ItemResultType } from 'js/components/controls/search-item-result';
import { isIE11 } from 'js/helpers/is-ie11';
import { useFirstMountState } from 'js/hooks/useFirstMountState';
import { ListItem, ListItemHandle } from './ListItem';
import { ListHeading, ListHeadingHandle } from './ListHeading';
import { ListLabel } from './ListLabel';
import { SectionData } from './SectionData';

type ItemRef = ListHeadingHandle | ListItemHandle | null;

interface Props {
  positioningClassName?: string;
  selectedItemKey?: string;
  sectionData: SectionData[];
  // isClickSelection is true when the change occurred due to a click on a dropdown list item
  onSelect: (key: string, isClickSelection: boolean) => void;
  // signature: onMount(containerNode)
  onMount?: (containerNode: unknown) => void;
  closeDropdown: () => void;
}

export interface ListHandle {
  moveSelectedItemByOffset: (offset: number) => void;
}

export const List = React.forwardRef(
  (props: Props, ref: React.Ref<ListHandle>) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    /*
     * references to all items have to be kept in a list to be able to scroll selected items
     * into view. reacts ref setters can be called multiple times, therefore they can not just
     * append (push) to a list. pushItemRef keeps an internal index and thereby allows
     * ref setters to be called multiple times with a deterministic behaviour.
     */
    const itemRefs = React.useRef<ItemRef[]>([]);
    const selectedItemRef = React.useRef<ItemRef>(null);
    const isFirstRender = useFirstMountState();

    React.useEffect(() => {
      props.onMount?.(containerRef);
    }, []);

    React.useEffect(() => {
      if (!isFirstRender && props.selectedItemKey) {
        scrollSelectedItemIntoView();
      }
    });

    React.useImperativeHandle(ref, () => ({
      moveSelectedItemByOffset(offset: number): void {
        const listKeys: string[] = [];
        for (const section of props.sectionData) {
          for (const item of section.items) {
            if ('key' in item) {
              listKeys.push(item.key);
            }
          }
        }

        let selectedItemIndex;
        if (props.selectedItemKey) {
          selectedItemIndex = listKeys.indexOf(props.selectedItemKey);
        } else {
          selectedItemIndex = -1;
        }

        if (selectedItemIndex < 0) {
          if (offset > 0) {
            selectedItemIndex = -1;
          } else {
            selectedItemIndex = listKeys.length;
          }
        }

        let nextSelectedItemIndex = selectedItemIndex + offset;
        nextSelectedItemIndex = Math.max(nextSelectedItemIndex, 0);
        nextSelectedItemIndex = Math.min(
          nextSelectedItemIndex,
          listKeys.length - 1
        );

        props.onSelect(listKeys[nextSelectedItemIndex], false);
      },
    }));

    function scrollSelectedItemIntoView(): void {
      if (!selectedItemRef.current || !containerRef.current) {
        return;
      }

      // find the first non selectable item component on top of the selected one
      let topInstance = selectedItemRef.current;
      const selectedItemIndex = itemRefs.current.indexOf(
        selectedItemRef.current
      );

      for (let i = selectedItemIndex - 1; i >= 0; i--) {
        const itemComponent = itemRefs.current[i];

        if (itemComponent === null) {
          continue;
        }

        if (itemComponent.isSelectable()) {
          break;
        }

        topInstance = itemComponent;
      }

      if (
        !(topInstance instanceof HTMLElement) ||
        !(selectedItemRef.current instanceof HTMLElement)
      ) {
        return;
      }

      // scroll area between top and bottom node into view
      const containerHeight = containerRef.current.clientHeight;
      const containerScrollTop = containerRef.current.scrollTop;

      const itemOffsetTop = topInstance.offsetTop;
      const itemOffsetBottom =
        selectedItemRef.current.offsetTop +
        selectedItemRef.current.offsetHeight;

      if (itemOffsetTop < containerScrollTop) {
        containerRef.current.scrollTop = itemOffsetTop;
      } else if (itemOffsetBottom > containerHeight + containerScrollTop) {
        containerRef.current.scrollTop = itemOffsetBottom - containerHeight;
      }
    }

    function onClick(key: string): void {
      props.onSelect(key, true);
    }

    const renderList = [];
    let refIndex = 0;
    const pushItemRef = (isSelected = false): ((ref: ItemRef) => void) => {
      const index = refIndex;
      refIndex += 1;

      if (isSelected) {
        return (ref) => {
          selectedItemRef.current = ref;
          itemRefs.current[index] = ref;
        };
      }
      return (ref) => {
        itemRefs.current[index] = ref;
      };
    };

    for (const section of props.sectionData) {
      if (section.heading) {
        renderList.push(
          <ListHeading
            ref={pushItemRef()}
            key={section.heading}
            label={section.heading}
          />
        );
      }

      // eslint-disable-next-line array-callback-return
      section.items.map((item, index) => {
        if (isIE11() && item.type === 'location-radius') {
          return;
        }

        switch (item.type) {
          case 'label':
            renderList.push(
              <ListLabel
                ref={pushItemRef()}
                key={`${item.label}-${index}`}
                label={item.label}
              />
            );
            break;
          case 'location-radius':
            renderList.push(
              <React.Fragment key={item.type}>
                <LocationRadius
                  label={item.label}
                  radius={item.radius}
                  validRadius={item.validRadius}
                  nearbyLabel={item.nearbyLabel}
                  closeDropdown={props.closeDropdown}
                />
                {index < section.items.length - 1 && (
                  <div className={listItemStyles.categorySeparator} />
                )}
              </React.Fragment>
            );
            break;

          case ItemResultType.CurrentLocation:
            renderList.push(
              <React.Fragment key={item.key}>
                <ListItem
                  ref={pushItemRef(item.key === props.selectedItemKey)}
                  type={item.type}
                  keyValue={item.key}
                  label={item.label}
                  labelRight={item.labelRight}
                  url={item.url}
                  isSelected={item.key === props.selectedItemKey}
                  onClick={onClick}
                />
                {index < section.items.length - 1 && (
                  <div className={listItemStyles.categorySeparator} />
                )}
              </React.Fragment>
            );
            break;
          default:
            renderList.push(
              <ListItem
                ref={pushItemRef(item.key === props.selectedItemKey)}
                type={item.type}
                key={item.key}
                keyValue={item.key}
                label={item.label}
                labelRight={item.labelRight}
                separatorLine={index < section.items.length - 1}
                url={item.url}
                isSelected={item.key === props.selectedItemKey}
                onClick={onClick}
              />
            );
            break;
        }
      });
    }

    return (
      <div ref={containerRef} className={props.positioningClassName}>
        {renderList}
      </div>
    );
  }
);

List.displayName = 'List';

export function findLabelForKey(
  sectionData: SectionData[],
  key: string
): string | null {
  for (const section of sectionData) {
    for (const item of section.items) {
      if ('key' in item && item.key === key) {
        return item.label;
      }
    }
  }

  return null;
}
