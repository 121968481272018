import React from 'react';
import clsx from 'clsx';
import { Text } from '@treatwell/ui';
import { formatAddressInline } from 'js/helpers/formatters';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { Context } from 'js/components/LocaleWrapper';
import styles from './Address.module.css';

interface Props {
  venue: VenueOutput;
  channelData: ChannelOutput;
  isActiveVenue: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function Address(props: Props): React.ReactElement {
  const { venue, channelData, isActiveVenue, onClick } = props;

  function Chain(props: { [key: string]: string }): React.ReactElement {
    const { children, ...rest } = props;

    return (
      <Context.Consumer>
        {(value) =>
          value.isWidget ? (
            <Text type="caption" {...rest}>
              {children}
            </Text>
          ) : (
            <a {...rest}>
              <Text type="caption">{children}</Text>
            </a>
          )
        }
      </Context.Consumer>
    );
  }

  const chain = venue.chain && (
    <Context.Consumer>
      {(value) => (
        <Chain
          className={clsx(styles.chain, {
            [styles.clickable]: !value.isWidget,
          })}
          href={venue.chain!.uri.desktopUri}
        >
          {value.i18n('page.venue.details.chain', venue.chain!.name)}
        </Chain>
      )}
    </Context.Consumer>
  );
  const addr = venue.location.address;
  const inlineAddress = formatAddressInline(
    addr.addressLines,
    addr.postalCode,
    channelData.code
  );

  return isActiveVenue ? (
    <Text
      as="div"
      type="caption"
      onClick={onClick}
      className={clsx(styles.address, { [styles.clickable]: !!onClick })}
    >
      {inlineAddress}
      {!!chain && (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          . {chain}
        </span>
      )}
    </Text>
  ) : (
    <Text as="div" type="caption" className={styles.inactiveAddress}>
      {inlineAddress}
    </Text>
  );
}
