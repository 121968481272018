import React, { useContext } from 'react';
import {
  AccoladeOutput,
  extractVectorImageUri,
} from 'js/model/rainbow/browse-page/AccoladeOutput';
import { LazyImage, Text } from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';
import styles from './VenueAccolades.module.css';

export const VenueAccolades = ({
  accolades,
}: {
  accolades: AccoladeOutput[];
}) => {
  const { i18n } = useContext(Context);

  return (
    <>
      <Text type="mdHeader" as="h2">
        {i18n('page.venue.section-titles.venue-awards')}
      </Text>
      <div className={styles.accolades}>
        {accolades.map((accolade) => (
          <LazyImage
            key={accolade.id}
            className={styles.accolade}
            src={extractVectorImageUri(accolade)}
            alt={accolade.name}
          />
        ))}
      </div>
    </>
  );
};
