/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { Stack, Inline, Text, Rating, StarSize, Viewport } from '@treatwell/ui';
import { formatNumber, FormatterChannelData } from 'js/helpers/formatters';
import styles from './VenueScore.module.css';
import { Context } from '../LocaleWrapper';

interface ExtraStyles {
  score?: string;
  rating?: string;
  summary?: string;
  stars?: string;
  reviews?: string;
}

interface Props {
  average: number;
  displayAverage?: string;
  count: number;
  extraStyles: ExtraStyles;
  channelData: FormatterChannelData;
  size?: StarSize;
  onClick?: (e: React.MouseEvent) => unknown;
  inVenueHeader?: boolean;
  inServicePopup?: boolean;
}

interface DefaultProps {
  size: StarSize;
}

export class VenueScore extends React.PureComponent<Props & DefaultProps> {
  static contextType = Context;

  declare context: React.ContextType<typeof Context>;

  public static readonly defaultProps: Readonly<DefaultProps> = {
    size: 32,
  };

  private renderReviewCount(): React.ReactNode {
    const { extraStyles, count } = this.props;
    const { i18n } = this.context;

    return (
      <Text
        as="div"
        type="caption"
        className={clsx(styles.reviews, extraStyles.reviews)}
      >
        {i18n(
          `page.venue.head.review-text.short.${
            count > 1 ? 'plural' : 'singular'
          }`,
          count
        )}
      </Text>
    );
  }

  private renderSummary(): React.ReactNode {
    const { extraStyles, average, size, inVenueHeader, inServicePopup } =
      this.props;

    return (
      <>
        <Inline
          justify={inVenueHeader ? 'center' : undefined}
          className={clsx(styles.stars, extraStyles.stars)}
        >
          <Rating rating={average} size={size} showRatingValue={false} />
        </Inline>
        <Viewport device="mobile" serverRender>
          <Inline justify={inServicePopup ? 'start' : 'end'}>
            {this.renderReviewCount()}
          </Inline>
        </Viewport>
        <Viewport device={['tablet', 'desktop']}>
          <Inline justify={inVenueHeader ? 'end' : 'start'}>
            {this.renderReviewCount()}
          </Inline>
        </Viewport>
      </>
    );
  }

  private renderScore(): React.ReactNode {
    const {
      average,
      displayAverage,
      channelData,
      extraStyles,
      inVenueHeader,
      inServicePopup,
    } = this.props;
    const averageRating =
      average > 0
        ? displayAverage || formatNumber(average, 1, channelData)
        : '-.-';

    return (
      <>
        <div className={clsx(styles.rating, extraStyles.rating)}>
          {averageRating}
        </div>
        <div className={clsx(styles.summary, extraStyles.summary)}>
          <Viewport device="mobile" serverRender>
            {inVenueHeader ? (
              <Inline align="center">{this.renderSummary()}</Inline>
            ) : inServicePopup ? (
              <Inline align="start">
                <Stack space="xxs">{this.renderSummary()}</Stack>
              </Inline>
            ) : (
              <Inline justify="end">
                <Stack space="xxs">{this.renderSummary()}</Stack>
              </Inline>
            )}
          </Viewport>
          <Viewport device={['tablet', 'desktop']}>
            <Stack space={inVenueHeader ? undefined : 'xs'}>
              {this.renderSummary()}
            </Stack>
          </Viewport>
        </div>
      </>
    );
  }

  public render(): React.ReactNode {
    const { onClick, extraStyles, inVenueHeader, inServicePopup } = this.props;

    return (
      <div className={clsx(styles.score, extraStyles.score)} onClick={onClick}>
        <Viewport device="mobile" serverRender>
          <Inline align="center">{this.renderScore()}</Inline>
        </Viewport>
        <Viewport device="tablet">
          {inVenueHeader || inServicePopup ? (
            <Inline align={inVenueHeader ? 'start' : 'center'}>
              {this.renderScore()}
            </Inline>
          ) : (
            <Stack>{this.renderScore()}</Stack>
          )}
        </Viewport>
        <Viewport device="desktop">
          <Inline align={inVenueHeader ? 'start' : 'center'}>
            {this.renderScore()}
          </Inline>
        </Viewport>
      </div>
    );
  }
}

VenueScore.contextType = Context;
