import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import styles from './VenuePortfolioCarouselGallery.module.css';

const PADDING_LEFT_DESKTOP = parseInt(styles.paddingDesktop, 10);
const PADDING_LEFT_MOBILE = parseInt(styles.paddingMobile, 10);
const PADDING_LEFT_TABLET = parseInt(styles.paddingTablet, 10);

export const GRID_GAP = parseInt(styles.gridGap, 10);
const DESKTOP_IMAGE_WIDTH = parseInt(styles.desktopImageWidth, 10);
export const MOBILE_IMAGE_WIDTH = parseInt(styles.mobileImageWidth, 10);
const MOBILE_BIG_IMAGE_WIDTH =
  parseInt(styles.mobileBigImageWidth, 10) + GRID_GAP;

const TABLET_BREAKPOINT = parseInt(styles['desktop-layout-width'], 10);
const MOBILE_BREAKPOINT = parseInt(styles['tablet-layout-width'], 10);

export const getSmallImageWidth = (windowWidth: number): number =>
  windowWidth > MOBILE_BREAKPOINT
    ? DESKTOP_IMAGE_WIDTH + GRID_GAP
    : MOBILE_IMAGE_WIDTH + GRID_GAP;

export const splitArrayInBigAndSmallImages = (images: ImageOutput[]) => {
  let splitImages: ImageOutput[][] = [];
  // Every 7th image in the array of images should be big on desktop and tablet.
  // For example if we receive the array of images with indices [0,1,2,3,4,5,6,7,8,9],
  // we split them into [[0],[1,2],[3,4],[5,6],[7],[8,9]]
  // with every 4th array of the big array comprising a big image ([0],[7])
  for (let start = 0; start < images.length; start += 7) {
    splitImages.push(images.slice(start, start + 1));
    splitImages.push(images.slice(start + 1, start + 3));
    splitImages.push(images.slice(start + 3, start + 5));
    splitImages.push(images.slice(start + 5, start + 7));
  }
  splitImages = splitImages.filter((imageArray) => imageArray.length > 0);
  return splitImages;
};

export const getDesktopGalleryPagesWidth = (
  images: ImageOutput[],
  windowWidth: number,
  // Offset from left window edge to the start of current page.
  left: number
): number[] => {
  const splitImages = splitArrayInBigAndSmallImages(images);

  let currentPageWidth = 0;
  const pageWidths = [];
  let padding = 0;

  if (windowWidth < MOBILE_BREAKPOINT) {
    padding = PADDING_LEFT_MOBILE;
  }
  if (windowWidth > MOBILE_BREAKPOINT && windowWidth < TABLET_BREAKPOINT) {
    padding = PADDING_LEFT_TABLET;
  }
  if (windowWidth > TABLET_BREAKPOINT) {
    padding = PADDING_LEFT_DESKTOP + left;
  }

  const imageWidth = getSmallImageWidth(windowWidth);

  for (let index = 0; index < splitImages.length; index++) {
    // Every 4th index of the split images array contains a big image.
    const currentImageWidth = index % 4 === 0 ? imageWidth * 2 : imageWidth;

    if (windowWidth - currentPageWidth - padding < currentImageWidth) {
      // No more room in current page, add page and start a new one.
      pageWidths.push(currentPageWidth);
      currentPageWidth = 0;
    }
    currentPageWidth += currentImageWidth;
  }
  return pageWidths;
};

export const getMobilePageCount = (imageCount: number): number =>
  Math.ceil((imageCount + 1) / 2);

export const getMobilePageNumber = (xPos: number): number =>
  calculateMobilePageNumber(
    xPos,
    MOBILE_IMAGE_WIDTH,
    MOBILE_BIG_IMAGE_WIDTH,
    GRID_GAP
  );

/**
 * Calculate which 'page' an x position corresponds to.
 * ('page' is the same as the grid column.)
 *
 * For a good user experience, the returned value should
 * correspond with which page will be snapped to (with CSS
 * 'scroll-snap') when drag scrolling ends.
 *
 * @param xPos the x scroll position.
 * @param imageWidth the width of a small image.
 * @param bigImageWidth the width of the large (1st) image.
 * @param gridGap the gap between columns in the grid.
 * @returns the 0-based page number an x position corresponds to.
 */
export const calculateMobilePageNumber = (
  xPos: number,
  imageWidth: number,
  bigImageWidth: number,
  gridGap: number
): number => {
  if (xPos < bigImageWidth + gridGap) {
    return xPos < bigImageWidth / 2 ? 0 : 1;
  }

  const xPosAfterFirstImage = xPos - bigImageWidth;
  const pageNumberWithinSmallImages =
    xPosAfterFirstImage / (imageWidth + gridGap);
  // Add 1 to account for the first (big) image
  const pageNumber = pageNumberWithinSmallImages + 1;

  return Math.round(pageNumber);
};
