import React from 'react';

interface Props {
  color: string;
}

export const IconMassage: React.FunctionComponent<Props> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 0C14.7303 0 15.753 0.888719 15.9613 2.05921C16.1339 2.02018 16.3146 2 16.5 2C17.7303 2 18.753 2.88872 18.9613 4.05921C19.1339 4.02018 19.3146 4 19.5 4C20.8255 4 21.91 5.03154 21.9947 6.33562L22 6.5V20C22 22.1422 20.316 23.8911 18.1996 23.9951L18 24V22C19.0544 22 19.9182 21.1841 19.9945 20.1493L20 20V6.5C20 6.22386 19.7761 6 19.5 6C19.2545 6 19.0504 6.17688 19.0081 6.41012L19 6.5V12H17V4.5C17 4.22386 16.7761 4 16.5 4C16.2545 4 16.0504 4.17688 16.0081 4.41012L15.9998 4.499L16 12H14V2.5C14 2.22386 13.7761 2 13.5 2C13.2545 2 13.0504 2.17688 13.0081 2.41012L13 2.5V12H11V4.5C11 4.22386 10.7761 4 10.5 4C10.2545 4 10.0504 4.17688 10.0081 4.41012L10 4.5V17H8.2132L5.16925 15.0975C4.86214 14.9056 4.46319 14.951 4.20711 15.2071C4.03069 15.3835 4.00036 15.6539 4.12278 15.8625L4.17586 15.9376L10.9658 24H8.35075L2.64603 17.2259C1.79639 16.2169 1.86019 14.7256 2.79289 13.7929C3.66178 12.924 4.99118 12.7341 6.06237 13.305L6.22925 13.4015L7.999 14.508L8 4.5C8 3.17452 9.03154 2.08996 10.3356 2.00532L10.5 2C10.6856 2 10.8666 2.02023 11.0407 2.05862C11.247 0.88872 12.2697 0 13.5 0Z"
      fill={props.color}
    />
  </svg>
);
