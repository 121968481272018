import React from 'react';
import { AccoladeBadge, AccoladeBadgeSize } from 'js/components/AccoladeBadge';
import {
  extractVectorImageUri,
  AccoladeOutput,
} from 'js/model/rainbow/browse-page/AccoladeOutput';
import { CmsEntities } from 'js/model/cms/cms-entities';

import styles from './style.module.css';

interface Props {
  accolades: AccoladeOutput[];
  cms: CmsEntities['accolade'];
}

export function CarouselAccoladeBadge(props: Props): React.ReactElement | null {
  const { accolades, cms } = props;
  const accoladeBadge = accolades.find((accolade) => accolade.featured);

  if (!accoladeBadge) {
    return null;
  }

  const tooltipText = cms[accoladeBadge.tooltipTextCMSKey]?.description || '';

  return (
    <AccoladeBadge
      positioningClass={styles.badgePosition}
      size={AccoladeBadgeSize.Large}
      imageUri={extractVectorImageUri(accoladeBadge)}
      tooltipText={tooltipText}
    />
  );
}
