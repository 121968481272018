import React from 'react';
import { connect } from 'react-redux';
import { Inline, Stack, Viewport, storage } from '@treatwell/ui';
import get from 'lodash.get';

import { setFulfillment, persistVenueBasket } from 'js/helpers/venue-basket';

import {
  trackBookingFlowEvent,
  trackBookingFlowStart,
  trackHandoverModalShow,
} from 'js/pages/VenuePage/tracking';
import flattenDeep from 'lodash.flattendeep';
import {
  getFragmentValue,
  extractLanguageCodeFromPath,
} from 'js/helpers/uri-util';

import { getVenueMenu } from 'js/redux-modules/venue-page';

import { PARAM_DATE, PARAM_START_TIME } from 'js/pages/VenuePage/uri';
import {
  ChannelLoyalty,
  ChannelOutput,
} from 'js/model/rainbow/content/ChannelOutput';
import { StateData } from 'js/model/rainbow/StateData';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { ReduxState } from 'js/model/state';
import { MenuItem } from 'js/service/venueService';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import {
  TreatmentVenueMenuItemOutput,
  VenueMenuOptionOutput,
} from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { usePrevious } from 'js/hooks/usePrevious';
import { isProductionEnvironment } from 'js/helpers/environment';
import { CmsPageVenue } from 'js/model/cms/cms-venue-page';
import { useIsMounted } from 'js/hooks/useIsMounted';
import { HandoverModal } from '../HandoverModal/HandoverModal';
import {
  ChannelCode,
  generateUalaWidgetUrl,
  saasIdsToTreatmentIds,
} from '../HandoverModal/helpers';
import { trackShowPatchTest } from './patchTestTracking';
import { PatchTestWarning } from './PatchTestWarning';
import styles from './VisitConfiguratorBar.module.css';
import { Button } from './Button';
import { Price, Summary } from './Summary';
import { getDateTimeUri } from './getDateTimeUri';
import { LoyaltyTotalContainer } from './LoyaltyTotal';

const FULFILLMENT_TYPE_APPOINTMENT = 'APPOINTMENT';

type Service = MenuItem & {
  id: string;
  priceRange: PriceRangeOutput;
  loyaltyPoints?: number;
};

type SelectedSku = Record<string, boolean>;
type SelectedService = Record<string, SelectedSku>;
export type SelectedServices = Record<string, SelectedService>;

interface Props {
  channel: ChannelOutput;
  selected: SelectedServices;
  selectedSkuIds: string[];
  cms: CmsPageVenue;
  pageData: StateData & { isMobileDevice: boolean };
  generateUri: (pageType: string, ...args: unknown[]) => string;
}

function VisitConfiguratorBarComponent(
  props: Props
): React.ReactElement | null {
  const { channel, selected, selectedSkuIds, cms, pageData } = props;

  const [showHandoverModal, setShowHandoverModal] =
    React.useState<boolean>(false);
  const [visitConfiguratorBarPosition, setVisitConfiguratorBarPosition] =
    React.useState<number>(0);
  const [isParentScrolling, setIsParentScrolling] =
    React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const prevSelected = usePrevious(selected);

  const venue = pageData.venue.venue;
  const menuGroups = venue.menu.menuGroups;
  const venueId = venue.id;
  const venueName = venue.name;

  React.useEffect(() => {
    if (getPatchRequirements(selected)) {
      trackShowPatchTest(getPatchRequirements(selected));
    }

    if (window === window.parent) {
      return;
    }

    window.addEventListener('message', handlePositionMessageFromParent);

    return () => {
      window.removeEventListener('message', handlePositionMessageFromParent);
    };
  }, []);

  React.useEffect(() => {
    persistVenueBasket(
      venueId,
      menuGroups,
      selected,
      FULFILLMENT_TYPE_APPOINTMENT
    );

    const oldState = getPatchRequirements(prevSelected);
    const newState = getPatchRequirements(selected);
    if (oldState !== newState) {
      trackShowPatchTest(newState);
    }
  });

  function handlePositionMessageFromParent(event: MessageEvent): void {
    let position = get(event, 'data.wahanda.visitConfiguratorBarPosition');
    const isParentScrolling = get(event, 'data.wahanda.scrolling');

    if ((!position && position !== 0) || typeof position !== 'number') {
      return;
    }

    const containerOffsetHeight: number = get(
      containerRef,
      'current.offsetHeight'
    );
    position = isParentScrolling
      ? -(window.innerHeight + containerOffsetHeight)
      : position;

    setVisitConfiguratorBarPosition(position);
    setIsParentScrolling(isParentScrolling);
  }

  function getPatchRequirements(selected?: SelectedServices): boolean {
    if (!selected) {
      return false;
    }

    const services = [];
    for (const groupId of Object.keys(selected)) {
      const group = menuGroups.find(
        (treatmentGroup) => treatmentGroup.id === parseInt(groupId, 10)
      );

      for (const serviceId of Object.keys(selected[groupId])) {
        const service = group!.menuItems.find(
          (treatmentService) => treatmentService.data.id === serviceId
        );
        services.push(service!.data);
      }
    }
    return services.some((treatment) => treatment.requiresPatchTest);
  }

  function getPrice(selectedSkus: Service[]): Price {
    const priceAmount = selectedSkus.reduce((cummulativePrice, sku) => {
      const skuPrice = sku.priceRange.minSalePriceAmount;
      return cummulativePrice + parseFloat(skuPrice);
    }, 0);

    const priceRange = selectedSkus.some((sku) => sku.priceRange.range);

    return {
      amount: priceAmount.toFixed(2),
      range: priceRange,
    };
  }

  function getLoyaltyTotal(selectedSkus: Service[]): number {
    if (!channel?.channelFeatures?.includes('loyalty_points_web')) {
      return 0;
    }

    return selectedSkus.reduce(
      (total, sku) => total + (sku.loyaltyPoints || 0),
      0
    );
  }

  function getSelectedSkus(selectedSkuIds: string[]): Service[] {
    const allSkus = menuGroups.reduce((skus1, group) => {
      skus1.push(
        ...group.menuItems.reduce((skus2, item) => {
          const optionGroups = item.data.optionGroups || [];
          skus2.push(
            ...optionGroups.reduce((skus3, optionGroup) => {
              const options = optionGroup.options;
              (skus3 as VenueMenuOptionOutput[]).push(...options);
              return skus3;
            }, [])
          );
          return skus2;
        }, [])
      );
      return skus1;
    }, []) as Service[];

    return allSkus.filter((sku) =>
      selectedSkuIds.some((item) => item === sku.id)
    );
  }

  function getServices(skuIds: string[]): VenueMenuItemTypeOutput[] {
    const allSkus = menuGroups.reduce((skus1, group) => {
      skus1.push(
        ...group.menuItems.reduce((skus2, item) => {
          (skus2 as VenueMenuItemTypeOutput[]).push(item);
          return skus2;
        }, [])
      );
      return skus1;
    }, []) as VenueMenuItemTypeOutput[];

    const selectedSkus = allSkus.filter((sku) => {
      const optionGroups =
        (sku.data as TreatmentVenueMenuItemOutput).optionGroups || [];
      const skuOptions = flattenDeep(
        optionGroups.map((optionGroup) =>
          optionGroup.options.map((option) => option.id)
        )
      );

      for (let i = 0; i < skuIds.length; i++) {
        const found = skuOptions.find((skuOption) => skuOption === skuIds[i]);

        if (found) {
          return found;
        }
      }

      return null;
    });
    return selectedSkus;
  }

  function checkout(fulfillmentType: string, selectedSkus: Service[]): void {
    // Treat the absence of a flag as true
    if (
      !venue.checkoutOnMarketplace &&
      venue.checkoutOnMarketplace !== undefined
    ) {
      // TODO: Persist fulfillment to local storage?
      setShowHandoverModal(true);
      trackHandoverModalShow(venueId);
      const services = getServices(selectedSkus.map((sku) => sku.id));
      const saasIds = services.map((service) => service.data.saasId);
      trackBookingFlowStart(services.length);

      const saasToTreatmentIds = saasIdsToTreatmentIds(saasIds as string[]);
      setTimeout(() => {
        const url = generateUalaWidgetUrl({
          isProductionEnvironment,
          channelCode: pageData.channel.code as ChannelCode,
          venueId,
          treatmentIds: saasToTreatmentIds,
        });
        if (url) {
          storage.local.removeItem(`venue-basket-${venueId}`);
          window.location.href = url;
        }
      }, 5000);
    } else {
      if (fulfillmentType) {
        setFulfillment(venueId, fulfillmentType);
      }

      const services = getServices(selectedSkus.map((sku) => sku.id));
      const price = getPrice(selectedSkus);

      const language = extractLanguageCodeFromPath(
        window.location.pathname,
        channel.code
      );
      const date = getFragmentValue(PARAM_DATE);
      const timeFrom = getFragmentValue(PARAM_START_TIME);

      const venue = {
        id: venueId,
        name: venueName,
      };

      // venue tracking
      storage.local.setItem('venue', JSON.stringify(venue));

      trackBookingFlowStart(services.length);
      trackBookingFlowEvent(fulfillmentType, price.amount, services).then(
        () => {
          const datetime = getDateTimeUri({
            venueId,
            selected,
            date,
            timeFrom,
            language,
          });
          window.location.href = datetime;
        }
      );
    }
  }

  // Get a flattened list of selected skus from the (necessary) hierarchy
  // that the menu maintains.
  const selectedSkus = getSelectedSkus(selectedSkuIds);
  const price = getPrice(selectedSkus);
  const loyaltyTotal = getLoyaltyTotal(selectedSkus);
  const selectedSkuCount = selectedSkuIds.length;
  const patchtest = getPatchRequirements(selected);
  const isBrowser = useIsMounted();
  const Cms = {
    contentCms: cms['vc-bar'].patchtestwarningpopup,
    headerCms: cms['vc-bar'].patchtestwarningpopupheader,
    buttonCms: cms['vc-bar'].patchtestwarningpopupbutton,
  };

  if (!selectedSkuCount || !isBrowser) {
    // Do not show the bar if there is nothing selected.
    return null;
  }

  return (
    <div data-cy="visit-configurator-bar">
      <Stack
        className={styles.vcbar}
        space="xs"
        style={{
          bottom: `${visitConfiguratorBarPosition}px`,
          visibility: isParentScrolling ? 'hidden' : 'visible',
        }}
        forwardRef={containerRef}
      >
        {patchtest && (
          <PatchTestWarning
            label={cms['vc-bar'].patchtestwarning}
            popupLabel={cms['vc-bar'].patchtestpopup}
            cms={Cms}
          />
        )}

        <Stack className={styles.contentContainer}>
          {loyaltyTotal > 0 && channel.loyalty && (
            <Viewport device={['tablet', 'mobile']}>
              <LoyaltyTotalContainer
                cms={cms}
                channel={channel as ChannelLoyalty}
                total={loyaltyTotal}
              />
            </Viewport>
          )}

          <Inline align="stretch" className={styles.content}>
            <Summary
              cms={cms}
              serviceCount={selectedSkuCount}
              price={price}
              loyaltyPoints={loyaltyTotal}
              channelData={channel}
            />
            <Inline align="stretch" className={styles.buttonContainer}>
              <Button
                label={cms['vc-bar']['appointment-btn']}
                primary
                onClick={() =>
                  checkout(FULFILLMENT_TYPE_APPOINTMENT, selectedSkus)
                }
              />
            </Inline>
          </Inline>
        </Stack>
      </Stack>
      {showHandoverModal && (
        <HandoverModal
          cms={{
            heading: cms['handover-modal'].heading,
            message: cms['handover-modal'].message,
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  selected: getVenueMenu(state).selected,
  selectedSkuIds: getVenueMenu(state).selectedSkuIds || [],
});

export const VisitConfiguratorBar = connect(mapStateToProps)(
  VisitConfiguratorBarComponent
);
